import React from 'react'
import "./styles/OurImpact.scss"
import conversion from "../../../assets/time.svg"
import reduction from "../../../assets/reduction.svg"
import time from "../../../assets/conversion.svg"

const SingleCard = ({image,text,count,style})=>{
    return (<div className="single-card" style={{...style}}>
        <div className="d-flex">
            <img src={image} alt="" />
            <div className="text-elements">
                <div className="number-percentage h1"><b>{count}</b></div>
                <p>{text}</p>
            </div>
        </div>
    </div>)
}
export default function OurImpact() {
    return (
        <div className="container-custom">
            <div id="ourimpact">
                <div className="row">
                    <div className="col-md-6">
                        <div className="impact-text text-size-4">
                            <div><b>Our Impact</b> From Our Projects</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="left-text">
                            <p>Thanks to our early adopters we now know what works and what doesn’t. Our latest releases have achieved terrific results, based on more than 10,000 virtual try-ons</p>
                            {/* <div className="btn btn-default">See Case Study</div> */}
                        </div>
                    </div>
                </div>
                <div className="impact-cards">
                    <SingleCard image={conversion} text="Increased conversion of items virtually tried on" count="34%" style={{ boxShadow: "0px 16px 12px 7px rgb(242 201 75 / 10%)" }} />
                    <SingleCard image={reduction} text="Reductions in returns" count="+21%" style={{ boxShadow: "0px 16px 12px 7px rgb(235 87 87 / 10%)" }} />
                    <SingleCard image={time} text="Time spent on Deepgears-powered products" count="3X" style={{ boxShadow: "0px 16px 12px 7px rgb(110 208 151 / 10%)" }} />
                </div>
            </div>
        </div>
    )
}
