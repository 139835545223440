import React from "react";
import { useSelector } from "react-redux";
import FormPopup from "./components/FormPopup";
import Header from "./components/Header";
import Impact from "./components/Impact";

const Referral = () => {
    const { slideInPopup } = useSelector((state) => state.model);

    return (
        <>
            <Header />
            <Impact />

            {slideInPopup && <FormPopup />}
        </>
    );
};

export default Referral;
