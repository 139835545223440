import React from "react";
import { Container, Div, Div2, List } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { setSlideInPopup } from "../../../model/index";

const Header = () => {
    const dispatch = useDispatch();

    return (
        <Container>
            <Div>
                <h1>Get a €50 amazon voucher</h1>
                <h2>
                    By introducing us to someone that owns/manages an online
                    fashion retail website
                </h2>
            </Div>

            <Div2>
                <div>
                    <h2>How it works</h2>
                    <List>
                        <li>
                            Fill in this{" "}
                            <span
                                onClick={() => dispatch(setSlideInPopup(true))}
                            >
                                form
                            </span>{" "}
                            generate your referral code
                        </li>
                        <li>
                            Follow the instructions we will email to you, to be
                            eligible for our voucher
                        </li>
                        <li>
                            Sit back and let us do all the work. We will be in
                            touch with you regardless of the outcome.
                        </li>
                    </List>
                </div>
            </Div2>
        </Container>
    );
};

export default Header;
