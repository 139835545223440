import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { setBackground } from "../../../model/DemoProcess"
import "../styles/Result.scss"
import { SketchPicker } from 'react-color';
import { EmptyList } from './Template';
import "./backgroundStyle.scss"

export default function Audio() {
    const dispatch = useDispatch()
    const handleChange = (color) => {
        dispatch(setBackground(color))
    }
    const background = useSelector((state) => state.demoProcess.background)
    return (
        <div id="demo-process-result">
            <div className="d-flex justidy-content-between header">
                <h4>Audio</h4>
            </div>
            <div className="d-flex all-images justify-align-content-center coming-soon">
                <EmptyList link={require("../../../assets/comingsoon.json")} widthStyle="300px" text="Audio feature is coming soon" />
            </div>
        </div>
    )
}

