import React from "react";

const validate = (values) => {
    let errors = {};

    // Name
    if (!values.fullname) {
        errors.fullname = "Name is required";
    }

    // Email
    if (!values.email) {
        errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = "Email address is invalid";
    }

    // Company
    if (!values.company) {
        errors.company = "Company is required";
    }

    return errors;
};

export default validate;
