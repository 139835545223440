import styled from "styled-components";

export const Container = styled.footer`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 0px;

    margin-top: 3rem;
    padding: 5rem 5rem;

    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;


    @media ${(props) => props.theme.breakpoints.sm} {
        display: flex;
        flex-direction: column;

        & img {
            margin-right: 0;
            margin-bottom: 2rem;
        }
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 5rem 2.5rem;
    }
`;

export const Div = styled.div`
    @media ${(props) => props.theme.breakpoints.sm} {
        margin-bottom: 2rem;
    }

    & h3 {
        margin-bottom: 1rem;
        font-size: 2rem;
        font-weight: 700;
    }

    & p {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        cursor: pointer;
    }

    & a {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
`;

export const Icons = styled.div`
    display: flex;

    & div {
        font-size: 2.5rem;
        cursor: pointer;
        margin-right: 0.5rem;
        transition: 0.2s;
    }

    & div:hover {
        color: #0e4da4;
    }
`;

export const Text = styled.p`
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 4rem;
`;
