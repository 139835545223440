import React from "react";
import HeroSection from "../components/Hero/HeroSection";
import ProductHeader from "../components/ProductHeader";
import AnimationSection from "../components/AnimationSection";
import ImpactSection from "../components/ImpactSection/Impact";
import Video from "../components/Video";
import { Helmet } from "react-helmet-async";

const Product = () => {
    return (
        <>
            <Helmet>
                <title>Our Solutions | DeepGears</title>
                <meta
                    name="description"
                    content="The most accurate try-on solution in the market, both online and in-store"
                />
                <link rel="canonical" href="/Product" />
            </Helmet>

            <ProductHeader />
            <AnimationSection />
            <Video />
            <ImpactSection />
            <HeroSection />
        </>
    );
};

export default Product;
