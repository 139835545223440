import React, { useState } from "react";
import { CtaButton } from "../../../components/Reusable/styles";
import { SlideIn } from "./styles";

const FormPopup = () => {
    const [name, setName] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!/\S+@\S+\.\S+/.test(email)) {
        } else {
        }
    };

    return (
        <SlideIn>
            <h1>Referral registration form</h1>

            <form onSubmit={handleSubmit}>
                <div>
                    <label>First name</label>
                    <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div>
                    <label>Last name</label>
                    <input
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                    />
                </div>

                <div>
                    <label>Email address</label>
                    <input
                        value={email}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                <CtaButton>Submit</CtaButton>
            </form>
        </SlideIn>
    );
};

export default FormPopup;
