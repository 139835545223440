import React from "react";
import { CtaButton, Wrapper } from "../Reusable/styles";
import InStore from "../../assets/inStoreImg.png";
import ProductImg from "../../assets/HomeHeader.png";
import img1 from "../../assets/ecomm1.png";
import img2 from "../../assets/ecomm4.png";
import img3 from "../../assets/ecomm6.png";
import img4 from "../../assets/ecomm5.png";
import { Div, Content, Heading, Text, Texts, Div2 } from "./styles";
import { Link } from "react-router-dom";

const ProductHeader = () => {
    return (
        <Wrapper>
            <Heading>Virtual try-ons</Heading>
            <Text>
                The most accurate try-on solution in the market, both online and
                in-store
            </Text>

            <Div>
                <div>
                    <h2>E-comm</h2>
                    <img src={ProductImg} alt="E-comm" />
                    <Link to="/Sales">
                        <CtaButton>Find out more</CtaButton>
                    </Link>
                </div>

                <div>
                    <h2>In-Store</h2>
                    <img className="inStore" src={InStore} alt="in Store" />
                    <Link to="/Sales">
                        <CtaButton>Find out more</CtaButton>
                    </Link>
                </div>
            </Div>

            <Heading className="second">E-comm</Heading>
            <Content>
                <Div2>
                    <img src={img1} alt="product" />
                    <Texts>
                        <h2>Realistic garment and fitting simulation</h2>
                        <p>
                            We perfectly simulate how items truly look and fit
                            reflecting their fabric properties
                        </p>
                    </Texts>
                </Div2>

                <Div2>
                    <img src={img2} alt="product" />
                    <Texts>
                        <h2>Garment digitization</h2>
                        <p>
                            Let our AI-based technology do all the work - we
                            digitise garments from a single picture in no time
                        </p>
                    </Texts>
                </Div2>

                <Div2>
                    <img src={img3} alt="Product" />
                    <Texts>
                        <h2>Hyper-accurate size recommendations</h2>
                        <p>
                            Our algorithms take user's measurements from a
                            single video and recommend users the best-fitting
                            size
                        </p>
                    </Texts>
                </Div2>

                <Div2>
                    <img src={img4} alt="It solution" />
                    <Texts>
                        <h2>Simple IT integration</h2>
                        <p>
                            We integrate seamlessly with any online
                            infrastructure with minimal effort required (as our
                            developer says 'it takes only three lines of code')
                        </p>
                    </Texts>
                </Div2>
            </Content>
        </Wrapper>
    );
};

export default ProductHeader;
