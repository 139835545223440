import React, { useEffect, useState } from "react";
import {
    Content,
    Div,
    Icon,
    Indicator,
    Indicators,
    Li,
    Text,
    Wrapper,
} from "./styles";
import avatar from "../../assets/avatar1.png";
import avatar2 from "../../assets/avatar2.png";
import { RiArrowDownSLine } from "react-icons/ri";
import Aos from "aos";
import "aos/dist/aos.css";

const AnimationSection = () => {
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);

    useEffect(() => {
        Aos.init({ duration: 700 });
    }, []);

    return (
        <Wrapper>
            <Indicators>
                <div data-aos="fade-down"></div>
                <Indicator></Indicator>
                <Indicator data-aos="fade-down"></Indicator>
            </Indicators>
            <Content>
                <Div>
                    <div>
                        <h1>We digitize your inventory for you</h1>
                        <ul>
                            <li onClick={() => setOpen(!open)}>
                                <Li>
                                    <p>
                                        State-of-the-art digitasion technology
                                    </p>
                                    <Icon>
                                        <RiArrowDownSLine />
                                    </Icon>
                                </Li>
                                {open && (
                                    <Text>
                                        We developed a proprietary, AI-based
                                        technology that allows us to digitise
                                        your entire inventory with minimal input
                                        from your side
                                    </Text>
                                )}
                            </li>

                            <li onClick={() => setOpen1(!open1)}>
                                <Li>
                                    <p>Hyper accurate digitasion</p>
                                    <Icon>
                                        <RiArrowDownSLine />
                                    </Icon>
                                </Li>
                                {open1 && (
                                    <Text>
                                        We digitise any garment to the highest
                                        quality, in every size, fabric and
                                        texture
                                    </Text>
                                )}
                            </li>

                            <li onClick={() => setOpen2(!open2)}>
                                <Li>
                                    <p>Fast and scalable approach</p>
                                    <Icon>
                                        <RiArrowDownSLine />
                                    </Icon>
                                </Li>
                                {open2 && (
                                    <Text>
                                        Let our technology do all the work, we
                                        typically take a few minutes for every
                                        garment
                                    </Text>
                                )}
                            </li>
                        </ul>
                    </div>
                    <img src={avatar} alt="digitased inventory" />
                </Div>

                <Div className="small" data-aos="fade-down">
                    <img src={avatar2} alt="digitased inventory" />
                    <div>
                        <h1>
                            We reconstruct the users 3D avatar
                            <br /> with the highest accuracy in the market
                        </h1>
                        <ul>
                            <li onClick={() => setOpen3(!open3)}>
                                <Li>
                                    <p>Market leading accuracy</p>
                                    <Icon>
                                        <RiArrowDownSLine />
                                    </Icon>
                                </Li>
                                {open3 && (
                                    <Text>
                                        Our algorithms are the most accurate on
                                        the market and are capable of taking
                                        users’ measurements from a single video
                                    </Text>
                                )}
                            </li>

                            <li onClick={() => setOpen4(!open4)}>
                                <Li>
                                    <p>Fastest approach in the market</p>
                                    <Icon>
                                        <RiArrowDownSLine />
                                    </Icon>
                                </Li>
                                {open4 && (
                                    <Text>
                                        There is a lot of data to crunch, but
                                        our technology is extremely fast and
                                        efficient. It only takes a minute to
                                        generate a user’s avatar
                                    </Text>
                                )}
                            </li>

                            <li onClick={() => setOpen5(!open5)}>
                                <Li>
                                    <p>One-off process</p>
                                    <Icon>
                                        <RiArrowDownSLine />
                                    </Icon>
                                </Li>
                                {open5 && (
                                    <Text>
                                        We remember users after their first
                                        access so that they have to complete our
                                        process only once
                                    </Text>
                                )}
                            </li>
                        </ul>
                    </div>
                </Div>
            </Content>
        </Wrapper>
    );
};

export default AnimationSection;
