import React, { useRef, useEffect } from 'react'
import database from "../../../assets/about/Database Administrator.png"
import registry from "../../../assets/about/Registry Editor.png"
import { animateGsap } from '../gsap/animation'
import "../styles/intergration.scss"

export default function Intergration() {
    const animateRef = useRef(null)
    useEffect(() => {
        animateGsap(animateRef)
    }, [])
  return (
      <div id="intergration" className="intergration" ref={animateRef}>
          <h1 className="d-flex justify-content-center ">
              <div>User Journey</div>
          </h1>
          <div className="row mx-0">
            <div className="col-md-6">
                  <h3>Start the process directly from the item page</h3>
                  <h6>Our webapp fully integrates with your website requiring only a few lines of code. Users will enojoy a smooth experience from the item page to our fitting room</h6>

                    <div className="icon-group-container">
                        <div className="d-md-flex icon-wrapper">
                            <div className="single-icon">
                              <img src={database} alt="" />
                                <div className="icon-heading h5">Single fully built-in package</div>
                              <div className="description h6">All our tech lies in a single page, ready to be deployed in any web enviroment</div>
                            </div>
                          <div className="single-icon">
                              <img src={registry} alt="" />
                              <div className="icon-heading h5" style={{ borderColor:"#F2C94C"}}>Seamless integration</div>
                              <div className="description h6">Our easy-to-customize webapp will offer the opportunity to fully match your brand style.</div>
                          </div>
                        </div>
                    </div>
            </div>
            <div className="col-md-6 video-part">
                  <div className="video-wrapper">
            <video src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/virtual_fitting_integration.mp4" autoPlay muted loop></video>
                  </div>
            </div>
          </div>
    </div>
  )
}
