import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Reusable/styles";
import { DivText, TextWrapper } from "../components/BlogCard/styles";
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.cjs.js";
import HeroSection from "../components/Hero/HeroSection";
import storyblokClient from "storyblok-js-client";

let Storyblok = new storyblokClient({
    accessToken: "92pkT7uT7xDfyOssgBGTmgtt",
    cache: {
        clear: "auto",
        type: "memory",
    },
});

const BlogPost = ({ match }) => {
    const [state, setState] = useState("");
    const resolver = new RichTextResolver();
    const idMatch = match.params.id;

    useEffect(() => {
        async function getData() {
            await Storyblok.getAll("cdn/stories", {})
                .then((response) => {
                    let data = response.find(
                        (post) => post.content._uid === idMatch
                    );
                    setState(data.content);
                })
                .catch((error) => {
                });
        }

        getData();
    }, []);

    return (
        <>
            <Wrapper>
                <DivText>
                    <h1>{state.title}</h1>
                    <img src={state.image} alt="blog-img" />
                    <TextWrapper>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: resolver.render(state.long_text),
                            }}
                        ></p>
                    </TextWrapper>
                </DivText>
            </Wrapper>
            <HeroSection />
        </>
    );
};

export default BlogPost;
