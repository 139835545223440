import React, { useState } from "react";
import { CtaButton } from "../Reusable/styles";
import {
    WrapperDiv,
    Info,
    Title,
    Text,
    Container,
    Grid,
    P,
    Psmall,
    CheckDiv,
    ErrorP,
} from "./styles";
import { Forms } from "../Reusable/styles";
import { makeStyles } from "@material-ui/core/styles";
import {
    TextField,
    Select,
    Checkbox,
    MenuItem,
    FormControl,
} from "@material-ui/core";
import emailjs from "emailjs-com";
import validateCareers from "../hooks/validateCareers";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
    },
}));

const CareerComp = () => {
    const classes = useStyles();
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        number: "",
        role: "",
    });
    const [country, setCountry] = useState("0");
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const changeCountry = (e) => {
        setCountry(e.target.value);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        setErrors(validateCareers(values));

        emailjs
            .sendForm(
                "tech@deepgears.com",
                "template_ktllo3s",
                e.target,
                "user_5lfoznhROSKNswNIxTTDd"
            )
            .then(
                (result) => {
                },
                (error) => {
                }
            );
        e.target.reset();
    };

    return (
        <WrapperDiv>
            <Info>
                <Title>Contact our recruiting team</Title>
                <Text>
                    We're happy that you are considering joining DeepGears and
                    to answer questions about the company.
                </Text>
                <Text>
                    Get in touch to enquire about any opening <br /> or just to
                    get to know each other
                </Text>
            </Info>

            <Container onSubmit={sendEmail}>
                <Grid>
                    <Forms>
                        <P>First Name *</P>
                        <TextField
                            placeholder={"Your first name"}
                            className={classes.root}
                            id="outlined-basic"
                            variant="outlined"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            type="text"
                        />
                        {errors.firstName && (
                            <ErrorP>{errors.firstName}</ErrorP>
                        )}
                    </Forms>

                    <Forms>
                        <P>Last Name *</P>
                        <TextField
                            placeholder={"Your last name"}
                            className={classes.root}
                            id="outlined-basic"
                            variant="outlined"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            type="text"
                        />
                        {errors.lastName && <ErrorP>{errors.lastName}</ErrorP>}
                    </Forms>

                    <Forms>
                        <P>Email address *</P>
                        <TextField
                            placeholder={"example@calvinklein.com"}
                            className={classes.root}
                            id="outlined-basic"
                            variant="outlined"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            type="email"
                        />
                        {errors.email && <ErrorP>{errors.email}</ErrorP>}
                    </Forms>

                    <Forms>
                        <P>Phone number *</P>
                        <TextField
                            placeholder={"+441234567"}
                            className={classes.root}
                            id="outlined-basic"
                            variant="outlined"
                            name="number"
                            value={values.number}
                            onChange={handleChange}
                        />
                        {errors.number && <ErrorP>{errors.number}</ErrorP>}
                    </Forms>

                    <Forms>
                        <P>Country *</P>
                        <FormControl variant="outlined">
                            <Select
                                value={country}
                                onChange={changeCountry}
                                className={classes.root}
                                name="country"
                            >
                                <MenuItem value={"0"} disabled>
                                    Please select
                                </MenuItem>
                                <MenuItem value={"UK"}>United Kingdom</MenuItem>
                                <MenuItem value={"IT"}>Italy</MenuItem>
                                <MenuItem value={"PT"}>Portugal</MenuItem>
                                <MenuItem value={"US"}>United States</MenuItem>
                                <MenuItem value={"W"}>
                                    Remote Worldwide
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Forms>

                    <Forms>
                        <P>Role of interest *</P>
                        <TextField
                            placeholder={"Software Engineer"}
                            className={classes.root}
                            id="outlined-basic"
                            variant="outlined"
                            name="role"
                            value={values.role}
                            onChange={handleChange}
                        />
                        {errors.role && <ErrorP>{errors.role}</ErrorP>}
                    </Forms>
                </Grid>

                <CheckDiv>
                    <Checkbox
                        name="keep_me_updated"
                        defaultChecked={false}
                        color="default"
                    />
                    <p>
                        Please keep me updated in DeepGears products and
                        services
                    </p>
                </CheckDiv>
                <Psmall>
                    By clicking 'Talk with us' you confirm that you agree to the
                    processing of your personal data as described in our Privacy
                    Statement. You can revoke your consent at any time you wish.
                </Psmall>
                <CtaButton type="submit">Send your message</CtaButton>
            </Container>
        </WrapperDiv>
    );
};

export default CareerComp;
