import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export const animateGsap=(ref)=>{
    gsap.registerPlugin(ScrollTrigger);
    return gsap.fromTo(ref.current, {
        opacity: 0.1
    }, {
        opacity: 1, duration: 0.3,
        scrollTrigger: {
            trigger: ref.current,
            start: "top bottom",
            end: "bottom center",
            scrub: true
        }
    })
}
