import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const RouteTracker = ({ history }) => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize("UA-199729560-1");
        ReactGA.pageview(location.pathname);

        ReactGA.event({
            category: "User",
            action: `Visited ${location.pathname} page`,
        });
    }, []);

    return <div></div>;
};

export default RouteTracker;
