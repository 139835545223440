import React from 'react'
import "./styles/Apptest.scss"
import shopifyLogo from "../../../assets/Shopify_logo_2018.svg"

export default function AppTest() {
    return (
        <div className="container-custom">
            <div id="app-test">
                <div className="row">
                    <div className="col-md-6 justify-content-center images">
                        <div className="video-container">
                            <div className="iphone-image"></div>
                            <div className="video-wrapper">
                                <video src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/deepgears_video+(1).mp4" autoPlay muted loop></video>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 text-section">
                        <div>
                            <div className="text-size-3">VIRTUAL FITTING ROOM</div>
                            <div className="text-size-4"><b>Let your customers try</b> any garment in 3D, on their personal avatar.</div>
                            <p>Users  will have the unique opportunity to try on different sizes and visualize how garments fit their body in real time, in a virtual fitting room fully integrated with your own website</p>
                            <div className="d-flex plugin">
                                <img src={shopifyLogo} alt="" className="shopify" />
                                <p className="shopify-r">plug-in available</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
