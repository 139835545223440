import React from 'react'
import Features from './components/Features'
import Intergration from './components/Intergration'
import Product from './components/Product'
import Solutions from './components/Solutions'
import "./styles/main.scss"

export default function AboutV2() {
    return (
        <div id="aboutv2">
            <div className="element-wrapper">
                <Intergration />
                <Features />
                <Product />
            </div>
            <Solutions />
        </div>
    )
}
