import React,{useEffect} from 'react'
import Result from '../components/DemoProcess/Result'
import SideBar from '../components/DemoProcess/SideBar'
import Video from '../components/DemoProcess/Video'

import { useSelector,useDispatch } from 'react-redux'
import PopUp from '../components/DemoProcess/PopUp'
import "./styles/demoProcessPage.scss"
import { useWindowSize } from 'react-window-size-hooks';
import { setResultPaneOpen } from '../model/DemoProcess'
import { mobileWidth, smallHeight } from '../components/breakpoints/breakpoint'

export default function DemoProcess() {
    const panelOpen = useSelector((state) => state.demoProcess.resultPaneOpen)
    const checkout = useSelector((state) => state.demoProcess.checkout)
    const { height,width } = useWindowSize();
    const dispatch = useDispatch()
    useEffect(() => {
        if ((height <= smallHeight) && (width <= mobileWidth)){
            dispatch(setResultPaneOpen(false))
        }
    }, [height])
    
    return (
        <div >
            <div className="container-fluid d-md-flex" style={{ maxWidth: "1300px",padding:"0px" }} id="demo-process-page">
                <SideBar />
                {panelOpen && <Result />}
                {checkout && <PopUp />}
                <Video />
            </div>
        </div>
    )
}
