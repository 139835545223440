import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setShowBasket, setCheckout, setAmount, setGarmentTemplate, setAnimation, setCameraView } from "../../model/DemoProcess"
import { EmptyList } from './SideBars/Template'
import "./styles/Video.scss"

export default function Video() {
    const showBasket = useSelector(state => state.demoProcess.showBasket)
    const panelOpen = useSelector((state) => state.demoProcess.resultPaneOpen)
    const video = useSelector((state) => state.demoProcess.video)
    const hasvideo = useSelector((state) => state.demoProcess.hasvideo)
    const width = `${panelOpen ? "50%" : "100%"}`
    const dispatch = useDispatch()
    const handleAddToBasket = () => {
        dispatch(setShowBasket(true))
    }
    return (
        <div id="demo-video" style={{ minWidth: width, maxWidth: width }}>
            <div className="basket-top">
                <div className={`d-flex justify-content-between basket-header ${showBasket && "p-0"}`}>
                    <div></div>
                    <Basket />
                </div>
            </div>
            <div></div>
            <div className="video">
                {hasvideo && <video src={video} autoPlay muted controls className="video-element"></video>}
                {!hasvideo && <img src={video} alt="" />}
            </div>
            <div>
                <div className="warning-text text w-100 bg-default"><div>Don't forget! This is just a template to let you  familiarize with our tools. The final product will be with your merchandise</div></div>
                <div className="cart d-flex justify-content-between">
                    <div className="text">Custom requirement? <span>Contact us!</span></div>
                    <div className="btn  btn-cart " onClick={handleAddToBasket}>
                        <div className="d-flex ">
                            <img src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Add+Shopping+Cart.png" alt="" />
                            <div className="btn-text">Add to basket</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function Basket() {
    const showBasket = useSelector(state => state.demoProcess.showBasket)
    return (
        <>
            {!showBasket ? <Cart /> : <div className="basket-all-details"><BasketDetails padding={true} close={true} clear={true} checkout={true} /></div>}
        </>
    )
}

export function SingleItem({ section, product, price, key }) {
    return <div className="single-item" key={key}>
        <div className="text section-text d-flex single-section">
            <div className="text section-text single-section">{section}</div>
            <div></div>
        </div>
        <div className="d-flex justify-content-between">
            <div className="text product-text">{product}</div>
            <div className="price text section-text"></div>
        </div>
    </div>
}

export function BasketDetails({ padding, close, clear, checkout }) {
    const demoProcess = useSelector((state) => state.demoProcess)
    const background = useSelector((state) => state.demoProcess.background)
    const calculateTotal = () => {
        let total = 0
        for (let key in demoProcess) {
            if (demoProcess[key]?.price && typeof (demoProcess[key]?.price) === "number") {
                total += demoProcess[key]?.price
            }
        }
        dispatch(setAmount(total))
        return total
    }
    const handlePriceText = (price) => {
        if (typeof (price) === "number") {
            return price
        }
        else {
            return 0
        }
    }
    const handleClearAll = () => {
        dispatch(setGarmentTemplate({
            title: null,
            price: 0
        }))
        dispatch(setAnimation("default"))
        dispatch(setCameraView("default"))
    }
    const [cart, setcart] = useState([
        {
            section: "Garment template",
            product: demoProcess.garmentTemplate.title,
            price: "€ " + handlePriceText(demoProcess.garmentTemplate.price)
        },
        {
            section: "Animation",
            product: demoProcess.animation.title,
            price: "€ " + handlePriceText(demoProcess.animation.price)
        },
        {
            section: "Camera",
            product: demoProcess.cameraView.title,
            price: "€ " + handlePriceText(demoProcess.cameraView.price)
        }
    ])
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(setShowBasket(false))
    }
    const handleCheckOut = () => {
        dispatch(setCheckout(true))
    }
    return <>
        <div className="d-flex garment-details" style={{ paddingTop: `${!padding && "0px"}`, pointerEvents: "all" }}>
            {close && (<div className="close-btn" onClick={handleClose}>
                <img src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/SideArrowMain.svg" alt="" />
            </div>)}
            {(!demoProcess.garmentTemplate.title) && <div className="empty-store"><EmptyList link={require("../../assets/emptyAnimation.json")} text="Nothing added to cart" widthStyle="200px" /></div>}
            {(demoProcess.garmentTemplate.title) && <div className="details">
                <div className="d-flex g-3 cart-basket">
                    <img src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Shopping+Cart.png" alt="" />
                    <div className="text">Your order</div>
                </div>
                {clear && (<div className="d-flex justify-content-between clear ">
                    <div></div>
                    <div className="btn-custom d-flex cart-basket" onClick={handleClearAll}>
                        <img src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Trash.png" alt="" />
                        <div className="text">
                            Clear all
                        </div>
                    </div>
                </div>)}
                {cart.map((item, idx) => {
                    return <SingleItem section={item.section} product={item.product} price={item.price} key={idx} />
                })}
                {checkout && (<div className="btn-custom btn-digitize" onClick={handleCheckOut}>
                    <div className="text">Digitize your own<br />merchandise</div>
                </div>)}
            </div>}
        </div>
    </>
}

export function Cart() {
    const dispatch = useDispatch()
    const handleClick = () => {
        dispatch(setShowBasket(true))
    }
    return <div className="d-flex cart-basket" onClick={handleClick} style={{ pointerEvents: "all" }}>
        <img src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Shopping+Cart.png" alt="" className="basket-image" />
        <div className="text basket-text">Basket</div>
    </div>
}

