import React from "react";
import HeroSection from "../components/Hero/HeroSection";
import { Link } from "react-router-dom";
import styled from "styled-components";
import img1 from "../assets/careers1.png";
import img2 from "../assets/careers2.png";
import img3 from "../assets/careers3.png";
import { CtaButton, Wrapper } from "../components/Reusable/styles";
import { Helmet } from "react-helmet-async";

const H1 = styled.h1`
    margin-bottom: 4rem;
    font-size: 7rem;
    font-weight: 700;

    text-align: center;
`;

const Div = styled(Wrapper)`
    display: flex;
    flex-direction: column;
    align-items: center;

    & button {
        padding: 1rem 2rem;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 12rem 2.5rem 3rem 2.5rem;
    }
`;

const Text = styled.div`
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 4rem;
`;

const HeadingGrid = styled.h2`
    margin: 10rem 0 6rem 0;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 6rem;
    grid-row-gap: 0px;

    padding: 0 5rem;

    @media ${(props) => props.theme.breakpoints.sm} {
        padding: 0;
        grid-column-gap: 3rem;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        display: flex;
        flex-direction: column;
    }
`;

const SubGrid = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${(props) => props.theme.breakpoints.xs} {
        margin-bottom: 2rem;
    }

    & img {
        width: 10rem;
        height: 10rem;
        margin-bottom: 2rem;
    }

    & h2 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 2rem;
    }

    & p {
        font-size: 2rem;
        text-align: center;
    }
`;

const grid = [
    {
        id: 0,
        img: img1,
        heading: "Early responsability",
        text: "We offer you the opportunity to work on projects of massive impact to our company",
    },
    {
        id: 1,
        img: img2,
        heading: "Flexible work",
        text: "Work wherever you want, whenever you want (as long as the targets are being achieved)",
    },
    {
        id: 2,
        img: img3,
        heading: "Personal development",
        text: "We commit to invest in our people and will provide you with the opportunity to grow outside your comfort zone",
    },
];

const Careers = () => {
    return (
        <>
            <Helmet>
                <title>Careers | DeepGears</title>
                <meta
                    name="description"
                    content="Join us in our journey to change the way millions of users shop and revolutionise the fashion world"
                />
                <link rel="canonical" href="/Careers" />
            </Helmet>

            <Div>
                <H1>
                    Take your career <br /> to the next level
                </H1>
                <Text>
                    Join us in our journey to change the way millions of users
                    shop and revolutionise the fashion world
                </Text>
                <Link to="/OurCareers">
                    <CtaButton>Enquire about our latest openings</CtaButton>
                </Link>

                <HeadingGrid>What we offer</HeadingGrid>
                <Grid>
                    {grid.map((g) => (
                        <SubGrid key={g.id}>
                            <img src={g.img} alt="first img" />
                            <h2>{g.heading}</h2>
                            <p>{g.text}</p>
                        </SubGrid>
                    ))}
                </Grid>
            </Div>
            <HeroSection />
        </>
    );
};

export default Careers;
