import styled from "styled-components";

export const Wrapper = styled.div`
    background-color: #f4f6fa;
    padding: 6rem 5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;

    display: flex;

    @media ${(props) => props.theme.breakpoints.sm} {
        flex-direction: column;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 12rem 2.5rem;
    }
`;

export const Content = styled.div`
    & div:last-child {
        margin: 0;
    }

    @media ${(props) => props.theme.breakpoints.sm} {
        & div:first-child {
            display: flex;
            flex-direction: column;
        }

        & div.small {
            display: flex;
            flex-direction: column-reverse;
        }
    }
`;

export const Indicators = styled.ul`
    margin-top: 4rem;
    width: 15%;

    @media ${(props) => props.theme.breakpoints.lg} {
        width: 10%;
    }

    @media ${(props) => props.theme.breakpoints.md} {
        width: 5%;
    }

    @media ${(props) => props.theme.breakpoints.sm} {
        display: none;
    }

    & li:last-child {
        margin-bottom: 0;
    }

    & div {
        margin-top: 22px;
        position: absolute;
        height: 58.5rem;
        width: 1px;
        background: #1d499a;
        border-radius: 20px;
        left: 62px;

        @media ${(props) => props.theme.breakpoints.lg} {
            margin-top: 17px;
            left: 46px;
        }

        @media screen and (max-width: 1300px) {
        }
    }
`;

export const Indicator = styled.li`
    width: 2.5rem;
    height: 2.5rem;
    background: none;
    border: 3px solid #1d499a;
    border-radius: 50%;
    list-style: none;
    margin-bottom: 58rem;
`;

export const Div = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 4rem;
    margin-bottom: 4rem;

    & h1 {
        font-size: 3rem;
        margin: 3rem 0;
    }

    & li {
        font-size: 2rem;
        list-style: none;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #ababab;
        cursor: pointer;
    }

    & ul li:last-child {
        border-bottom: none;
    }

    & img {
        width: 50rem;

        @media ${(props) => props.theme.breakpoints.sm} {
            align-self: center;
        }

        @media ${(props) => props.theme.breakpoints.xxs} {
            width: 40rem;
        }

        @media screen and (max-width: 350px) {
            width: 32.5rem;
        }
    }
`;

export const Li = styled.div`
    display: flex;
    justify-content: space-between;
    @media ${(props) => props.theme.breakpoints.sm} {
        flex-direction: row !important;
    }
`;

export const Icon = styled.div`
    color: #1d499a;
`;

export const Text = styled.p`
    margin-top: 2.5rem;
    font-size: 1.5rem;
    max-width: 50rem;

    @media ${(props) => props.theme.breakpoints.md} {
        max-width: fit-content;
    }
`;
