import React from 'react'
import "./style/Footer.scss"
import { StartButton } from "./Header"

export default function Footer() {
    return (
        <div className="container-fluid" id="demo-footer">
            <div className="row justify-content-center">
                <h1>3. Let us digitize your merchandise and deliver the final video to you</h1>
                <h4>Our digital designers can digitize complex garments from a single set of pictures.
                    Alternatively, you can also send us your merchandise and we will send it back in 3-5 working days</h4>
                <StartButton />
            </div>
        </div>
    )
}
