import React, { useState, createContext, useEffect } from "react";
import storyblokClient from "storyblok-js-client";

let Storyblok = new storyblokClient({
    accessToken: "92pkT7uT7xDfyOssgBGTmgtt",
    cache: {
        clear: "auto",
        type: "memory",
    },
});

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [content, setContent] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getData() {
            await Storyblok.getAll("cdn/stories", {})
                .then((response) => {
                    setContent(response);
                    setIsLoading(false);
                })
                .catch((error) => {
                });
        }

        getData();
    }, []);

    return (
        <AppContext.Provider
            value={{
                content: [content],
                isLoading: [isLoading],
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
