import React from 'react'
import "./styles/Header.scss"
import iphone from "../../../assets/iphoneframe.png"
import { Link } from "react-router-dom";

export default function Header() {
    return (
        <div className='container-custom' style={{display:"block"}}>
            <div className="background">
                <video src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/video+background+1k.mp4" autoPlay muted  loop></video>
            </div>
            <div id="header-home">
                <div className="row">
                    <div className="col-md-12">
                        <div className="header-content">
                            <div>
                                <div className="header-text text-size-4">
                                    <span className="text-size-4">Empower your</span><br />
                                    <b>e-commerce with 3D </b><br />
                                    technology<br />
                                </div>
                                <p>
                                    From virtual fitting rooms to hyper-realistic animations, we have a wide range of products to make your merchandise truly stand out
                                </p>
                                <div className="cta-btn d-flex">
                                    <Link to="/sales"><div className="btn btn-primary">Get started</div></Link> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
