import axios from "axios"
import _ from "lodash"
export const config={
    "apiRoot": process.env.REACT_APP_API_ROOT,
}

export const endpoints={
    allGarmentTemplates:"app/demo/garmentTemplates",
    allAnimation:"app/demo/animation",
    allCameraView:"app/demo/camera-view",
    uploadFile:"app/demo/upload",
    payment:"app/demo/payment",
    storeUserInfo:"app/demo/customerData"
}
export const imagesLinks={
    imageSource:"https://deep-gears-storage.s3.eu-west-2.amazonaws.com/3D_canva/garment_animations/"
}
export const groupBy={
    animation:"animation",
    camera:"camera_view",
}
export const API = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT
});


export const hasCache = (key, data) => {
    const cacheMap = JSON.parse(localStorage.getItem('api'));
    if (!cacheMap) {
        return false;
    }
    if (cacheMap.length) {
        const cacheIndex = cacheMap.findIndex((element) => _.isEqual(element.data, data));
        if (cacheMap[cacheIndex]) {
            return cacheMap[cacheIndex].result;
        }
        return false;
    }
    return false;
};

export const storeCache = (key, data, result) => {
    let cacheMap = JSON.parse(localStorage.getItem('api'));
    if (!cacheMap) {
        cacheMap = [];
    }
    let checkCacheExist = hasCache(key, data);
    if (checkCacheExist) {
        return checkCacheExist;
    }
    cacheMap.push({ key: key, data: data, result: result });
    localStorage.setItem('api', JSON.stringify(cacheMap));
};

export const memoizeApiPost = async (route, input) => {
    let checkcache = hasCache(route, input);
    if (checkcache) {
        return checkcache;
    } else {
        let response = await API.post(route, input);
        storeCache(route, input, response);
        return response;
    }
};

export const clearCache = () => {
    localStorage.setItem('api', JSON.stringify([]));
};
export const handlePost=async ({url,data})=>{
    try {
        let result = await API.post(url, data);
        if (result) {
            return result.data
        }  
    } catch (error) {
    }
}