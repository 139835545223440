import React from 'react'
import "./style/Header.scss"
import { useHistory } from "react-router-dom"

export default function Header() {
  return (
    <div className="container-fluid" id="demo-intro-header">
        <div className="row">
            <div className="col-md-6">
                  <h1>3D technology for marketers</h1>
                  <h4>Create engaging 3D animations for your merchandise in less than 2 minutes</h4>
                  <StartButton />
                  <div className="pitch-icon">
                    <div className="d-flex gap-4">
                          <img src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Code+Blocks.png" alt="" />
                          <div>Drag&Drop - No coding skills required!</div>
                    </div>
                      <div className="d-flex gap-4">
                          <img src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Get+Cash.png" alt="" />
                          <div>From 69EUR per animation</div>
                      </div>
                  </div>
            </div>
            <div className="col-md-6 justify-content-center d-grid">
                  <video src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/combined_final.mp4" autoPlay muted loop></video>
            </div>
        </div>
    </div>
  )
}
export function StartButton() {
  const history = useHistory()
  const handleClick=()=>{
    history.push("/demo-process")
  }
  return <div className="btn btn-start" onClick={handleClick}>
    <div>
      <p className="p-0 m-0">Start for free</p>
      <h6>(No sign up required)</h6>
    </div>
  </div>
}

