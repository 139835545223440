import React from "react";
import styled from "styled-components";
import HeroSection from "../components/Hero/HeroSection";
import { Wrapper } from "../components/Reusable/styles";
import { Helmet } from "react-helmet-async";

const Title = styled.h2`
    margin-bottom: 2rem;
    font-size: 2.5rem;
`;

const Text = styled.p`
    font-size: 1.8rem;
    margin-bottom: 2rem;

    &.heading {
        text-align: center;
        margin-bottom: 2rem;
    }

    &.sub-heading {
        text-align: center;
        margin-bottom: 6rem;
    }
`;

const UL = styled.ul`
    margin-bottom: 2rem;
`;

const LI = styled.li`
    font-size: 1.5rem;
    margin-left: 2rem;
`;

const Privacy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | DeepGears</title>
                <meta
                    name="description"
                    content="Collection of personal data when visiting our website"
                />
                <link rel="canonical" href="/Privacy" />
            </Helmet>

            <Wrapper className="privacy">
                <Text className="heading">Privacy policy</Text>
                <Text className="sub-heading">
                    DeepGears Ltd <br /> Updated: March 22nd, 2021
                </Text>
                <Text>
                    Thank you for visiting the company website of DeepGears Ltd
                    and it’s subsidiaries (in the following referred to as
                    “DeepGears”). We consider the protection of your data very
                    important when using our website and focus on proper
                    handling of your personal data. All data will be collected,
                    used and shared solely within the statutory provisions of
                    the applicable data protection laws. In the following, we
                    will state and explain what data we collect, how it is
                    handled by us and the rights granted to you regarding our
                    use of your data.
                </Text>
                <Text>
                    DeepGears Ltd, located at Flat 115, Rosemary place, 85 Royal
                    Mint Street, London, E1 8SQ <br /> United Kingdom, is the
                    operator of the website https://www.deepgears.com/. We are
                    also responsible for the application of the same name and
                    for the solution providing virtual try-ons to online
                    shoppers in our partner’s online shops.
                </Text>
                <Text>
                    We are responsible for the collection, processing and use of
                    personal data in terms of the General Data Protection
                    Regulation from the European Union (GDPR).
                </Text>
                <Title>Web analysis services</Title>
                <Text>
                    For purposes of need-based design and continuous
                    optimization of our pages, we use web analysis services
                    ("Google Analytics"). In this context, pseudonymized user
                    profiles are created and cookies are used. The
                    cookie-created information on your use of our website such
                    as
                </Text>
                <UL>
                    <LI>Browser type and -version,</LI>
                    <LI>Operating system,</LI>
                    <LI>Referrer URL (previously visited page),</LI>
                    <LI>Host name of the accessing computer, and</LI>
                    <LI>
                        Time of server request is forwarded to and stored on
                        servers located within the European Union.
                    </LI>
                </UL>
                <Text>
                    The information is used to analyze the use of the website,
                    to compile reports on website activities, and to perform
                    further services connected with website use and Internet use
                    for purposes of market research and need-based design of
                    these Internet pages. Such information will also be
                    forwarded to third parties if this is required by law or
                    such information is processed by such third party by order.
                </Text>
                <Text>
                    In no case will the IP address be combined with other data
                    corresponding to the customer. The IP addresses will be
                    anonymized so that allocation is not possible (IP masking).
                </Text>
                <Text>
                    You may prevent installation of cookies by making the
                    corresponding settings in the browser software; however, we
                    expressly point out that this will prevent comprehensive use
                    of all functions of this website. You may object to creation
                    of user profiles at any time. The contact data can be used
                    for notification of objection. As an alternative, please
                    follow the instructions given at:
                    http://tools.google.com/dlpage/gaoptout?hl=en.
                </Text>
                <Title>Collection of personal data</Title>
                <Text>
                    Collection of personal data when visiting our website <br />{" "}
                    When visiting our website, our servers temporarily store
                    each access in a log file. Unless otherwise indicated by
                    you, the following data is collected and stored by us until
                    automatic deletion after a maximum of four weeks:
                </Text>
                <UL>
                    <LI>
                        IP address of the requesting computer
                        (shortened/hashed);
                    </LI>
                    <LI>Date and time of access;</LI>
                    <LI>Name and URL of retrieved file;</LI>
                    <LI>Website from which the access originated;</LI>
                    <LI>Operating system of your computer and your browser;</LI>
                    <LI>Name of your Internet access provider.</LI>
                </UL>
                <Text>
                    The stored IP addresses of users are deleted after cessation
                    of use or shortened so that details of personal or material
                    information are no longer recognizable or are only
                    recognizable through a disproportionate investment of time,
                    cost and labor of a specific or identifiable real human
                    being. Collection and processing of such data takes place
                    with the purpose of facilitating use of our website
                    (connection build-up), ensuring continuous system safety and
                    stability, enabling technical administration of network
                    infrastructure and optimization of our online services and
                    for internal statistical purposes. We also use cookies, web
                    analytics tools, and social-plugins.{" "}
                </Text>
                <Text>
                    Collection of personal data when submitting through the
                    contact form <br /> By clicking “Talk to us” on the “Talk to
                    us” contact form, you consent to the processing and storage
                    of personal data entered by you in the form fields. This
                    also includes sharing the data with our subsidiaries. This
                    data includes your:
                </Text>
                <UL>
                    <LI>
                        Personal identification and employment information
                        (first name, last name, job title, country, company,
                        phone number, work email, annual sales, department,
                        role)
                    </LI>
                    <LI>Correspondence message / communication</LI>
                </UL>
                <Title>Collection of personal data for marketing </Title>
                <Text>
                    DeepGears would like to send you information about products
                    and services of ours that we think are relevant to you
                    and/or your company of employment. By selecting the “Please
                    keep me updated on DeepGears products and services” check
                    box, you consent to the processing, storage and sharing of
                    your personal data in order to receive information on
                    products and services via email newsletter or downloadable
                    documents.
                </Text>
                <Text>
                    Receivers and processors of personal data The following list
                    outlines data collection and processing providers that may
                    be used by DeepGears.
                </Text>
                <UL>
                    <LI>Facebook, Inc.</LI>
                    <LI>https://www.facebook.com/policy.ph</LI>
                    <LI>1 Hacker Way, Menlo Park, CA 94025, USA</LI>
                </UL>

                <UL>
                    <LI>Twitter, Inc.</LI>
                    <LI>https://twitter.com/en/privacy </LI>
                    <LI>
                        1355 Market Street, Suite 900; San Francisco, CA 94103,
                        USA
                    </LI>
                </UL>

                <UL>
                    <LI>LinkedIn Corporation</LI>
                    <LI>https://www.linkedin.com/legal/privacy-policy </LI>
                    <LI>2029 Stierlin Court Mountain View, CA 94043, USA</LI>
                </UL>

                <UL>
                    <LI>Salesforce.com, Inc.</LI>
                    <LI>
                        https://www.salesforce.com/company/privacy/full_privacy/#
                    </LI>
                    <LI>
                        Salesforce Tower, 415 Mission Street, 3rd Floor, San
                        Francisco, CA 94105, USA
                    </LI>
                </UL>

                <UL>
                    <LI>HubSpot, Inc.</LI>
                    <LI>
                        25 First Street, 2nd Floor Cambridge, MA 02141, USA{" "}
                    </LI>
                </UL>

                <UL>
                    <LI>Google, Inc.</LI>
                    <LI>
                        https://policies.google.com/
                        https://support.google.com/analytics/
                        answer/6004245?hl=en
                    </LI>
                    <LI>
                        600 Amphitheatre Parkway, Mountain View, CA 94043, USA{" "}
                    </LI>
                </UL>

                <Title>Correction / Opt-out </Title>
                <Text>
                    Upon your request, we will correct or update your personal
                    information. You can make these requests by emailing.
                </Text>
                <Text>
                    At any time, you can opt-out of receiving offerings-related,
                    promotions-related and inquiry response-related messages
                    from DeepGears. If you choose to do so, DeepGears will
                    delete all your personal data collected so far. From this
                    point onwards you will no longer receive any further
                    information on products and services.
                </Text>

                <Title>Cookies</Title>
                <Text>
                    Cookies simplify a variety of processes, one of which is to
                    make your visit to the website easier, more pleasant and
                    more meaningful. Cookies are alphanumeric information files
                    that are automatically stored by your browser on the hard
                    drive of your computer when visiting our internet page.
                </Text>

                <Text>
                    Deactivation of cookies may also deactivate some functions
                    of our website. The anonymous and encrypted data gathered by
                    cookies is not intended for gathering personal data.
                    Furthermore, the data collected from cookies will not be
                    passed to third parties. The following cookies are used on
                    our website and when using our application:
                </Text>

                <Text>
                    connect.sid: Session cookie from DeepGears to improve the
                    functionality and identification of the user.
                </Text>

                <Title>Safety and security</Title>
                <Text>
                    We use suitable technical and organizational safety measures
                    to protect your data from accidental or intentional
                    manipulation, partial or complete loss and/or destruction as
                    well as unauthorized access. Our security measures are
                    subject to continuous improvement in accordance with current
                    technological developments.
                </Text>

                <Text>
                    Provision of information, as well as correction and deletion
                    of data
                </Text>

                <Text>
                    You are entitled to receive information on your stored
                    personal data as well as to have incorrect data corrected
                    and to block and/or delete data. To receive information on
                    your personal data or to request correction of incorrect
                    data, including blocking or deletion of such data, or for
                    further questions on the use of your personal data, please
                    contact:
                </Text>

                <Text>
                    DeepGears Ltd
                    <br />
                    Flat 115, Rosemary place
                    <br />
                    85 Royal Mint Street
                    <br />
                    London
                    <br />
                    E1 8SQ United Kingdom
                    <br />
                    info@deepgears.com
                </Text>
            </Wrapper>

            <HeroSection />
        </>
    );
};

export default Privacy;
