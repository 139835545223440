import styled from "styled-components";

export const Heading = styled.h1`
    font-size: 4.5rem;
    text-align: center;
    margin-bottom: 3rem;

    &.second {
        margin-bottom: 3rem;
        font-size: 3.8rem;
    }
`;

export const Text = styled.p`
    font-size: 3rem;
    text-align: center;
    margin-bottom: 3rem;
`;

export const Div = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8rem;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }

    & div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3rem;

        @media screen and (max-width: 800px) {
            margin-bottom: 4rem;
        }

        & h2 {
            font-weight: 300;
            margin-bottom: 2rem;
        }

        & button {
            width: 18rem;
            padding: 1rem 0;
        }

        & img {
            width: 42.5rem;
            height: auto;
            margin-bottom: 2rem;

            @media ${(props) => props.theme.breakpoints.xs} {
                width: 33rem;
            }
        }

        & img.inStore {
            width: 43rem;
            height: auto;
            margin-bottom: 4.3rem;

            @media ${(props) => props.theme.breakpoints.xs} {
                width: 33.5rem;
            }
        }
    }
`;

export const Content = styled.div`
    margin-top: 6rem;
    padding: 0 10rem;
    @media ${(props) => props.theme.breakpoints.sm} {
        padding: 0 4.5rem;
    }

    display: flex;
    flex-direction: column;

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 0 2.5rem;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 0;
    }
`;

export const Div2 = styled.div`
    margin-bottom: 3.5rem;
    display: flex;
    align-items: center;

    & img {
        height: auto;
        width: 13rem;
        margin-right: 8rem;

        @media ${(props) => props.theme.breakpoints.sm} {
            margin-right: 5rem;
        }

        @media ${(props) => props.theme.breakpoints.xs} {
            margin-right: 0;
            margin-bottom: 2rem;
        }
    }

    & img.png {
        width: 15rem;
        height: auto;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        flex-direction: column;
        justify-content: center;
    }
`;

export const Texts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & h2 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    & p {
        font-size: 2rem;

        @media ${(props) => props.theme.breakpoints.xs} {
            text-align: center;
        }
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        align-items: center;
    }
`;
