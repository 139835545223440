import React from 'react'
import "./styles/Cta.scss"
import { Link } from "react-router-dom";

export default function Cta() {
    return (
        <div id="cta" >
            <div className="wrapper">
                <div className="text-size-4 text-capitalize text-element">
                    Bring your<br /> <b>online marketplace</b><br /> to a whole <b>new level</b>
                </div>
                <div className="d-flex ctas">
                    <Link to="/sales">
                        <div className="btn btn-default">
                            Contact Us
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
