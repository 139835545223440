import React, { useEffect, useState, useRef } from "react";
import HeroSection from "../components/Hero/HeroSection";
import styled from "styled-components";
import { CtaButton, Forms } from "../components/Reusable/styles";
import { Helmet } from "react-helmet-async";
import validate from "../components/hooks/validate";
import ModalComp from "../components/modal/Modal";
import Modal from "react-modal";


import { makeStyles } from "@material-ui/core/styles";
import {
    TextField,
    Select,
    Checkbox,
    MenuItem,
    FormControl,
} from "@material-ui/core";
import {
    WrapperDiv,
    Container,
    Info,
    Title,
    Text,
    CheckDiv,
    Psmall,
    P,
    ErrorP,
} from "../components/Ourcareers/styles";
import emailjs from '@emailjs/browser';
import "./sales.scss"

// Styles
const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
    },
}));

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    margin-bottom: 3rem;

    @media ${(props) => props.theme.breakpoints.xxs} {
        display: flex;
        flex-direction: column;
    }
`;

const Textarea = styled.textarea`
    width: 100%;
    padding: 1rem;
    font-family: "myriad";
    font-weight: 300;
    resize: none;
    font-size: 1.5rem;
    border: 1px solid #c4c4c4;

    border-radius: 5px;
`;

// Component
const Sales = (props) => {
    const classes = useStyles();
    const ref = useRef(null)
    const [values, setValues] = useState({
        fullname: "",
        email: "",
        company: "",
        message: "",
    });
    const [role, setRole] = useState("0");
    const [know, setKnow] = useState("0");

    const [errors, setErrors] = useState({});
    const [open, setIsOpen] = useState(false);

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };


    const submitForm = (e) => {
        e.preventDefault();
        setErrors(validate(values));

        if ((values.fullname || values.email === "") && values.company === "") {
        } else {
            var templateParams = {
                fullname: values.fullname,
                email: values.email,
                company: values.company,
                message: values.message,
                role: role,
                know: know,
                keep_me_updated: true,
            };
            emailjs
                .send(
                    "tech@deepgears.com",
                    "template_zms6ysl",
                    templateParams,
                    "user_5lfoznhROSKNswNIxTTDd"
                )
                .then(
                    async (result) => {
                        const handleOpen = () => {
                            setIsOpen(true);
                            setTimeout(() => {
                                setIsOpen(false);
                            }, 3000);
                        }
                        handleOpen()
                    },
                    (error) => {
                    }
                );
            ref.current.reset();
        }
    };

    return (
        <>
            {props.text && (
                <Helmet>
                    <title>Sales | DeepGears</title>
                    <meta
                        name="description"
                        content="Get in touch with us to understand how
                    DeepGears can help you increase your e-comm conversion and reduce returns."
                    />
                    <link rel="canonical" href="/Sales" />
                </Helmet>
            )}

            <WrapperDiv id="wrapper-div">
                {props.text && <Info className="info">
                    <Title>Contact our Sales team</Title>
                    <Text>
                        Get in touch with us to understand how <br />
                        DeepGears can help you increase your e-comm conversion
                        and reduce returns.
                    </Text>

                    <Text>
                        <li>Get pricing information</li>
                        <li>Explore use cases</li>
                        <li>Try our demo</li>
                    </Text>
                </Info>}
                <form ref={ref} onSubmit={submitForm}>
                    <Container >
                        <Grid>
                            <Forms>
                                <P>Full Name *</P>
                                <TextField
                                    placeholder={"Your full name"}
                                    className={classes.root}
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="fullname"
                                    value={values.fullname}
                                    onChange={handleChange}
                                />
                                {errors.fullname && (
                                    <ErrorP>{errors.fullname}</ErrorP>
                                )}
                            </Forms>

                            <Forms>
                                <P>Work email address *</P>
                                <TextField
                                    placeholder={"example@calvinklein.com"}
                                    className={classes.root}
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="email"
                                    type="email"
                                    value={values.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <ErrorP>{errors.email}</ErrorP>}
                            </Forms>

                            <Forms>
                                <P>company *</P>
                                <TextField
                                    placeholder={"Calvin Klein"}
                                    className={classes.root}
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="company"
                                    value={values.company}
                                    onChange={handleChange}
                                />
                                {errors.company && (
                                    <ErrorP>{errors.company}</ErrorP>
                                )}
                            </Forms>

                            <Forms>
                                <P>Role *</P>
                                <FormControl variant="outlined">
                                    <Select
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        className={classes.root}
                                        name="role"
                                    >
                                        <MenuItem value={"0"} disabled>
                                            Please select
                                        </MenuItem>
                                        <MenuItem value={"CXO"}>CXO</MenuItem>
                                        <MenuItem value={"AVP/VP"}>AVP/VP</MenuItem>
                                        <MenuItem value={"Director"}>
                                            Director
                                        </MenuItem>
                                        <MenuItem value={"Manager"}>
                                            Manager
                                        </MenuItem>
                                        <MenuItem value={"ASS/ENG"}>
                                            Associate / Engineer
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Forms>

                            <Forms>
                                <P>How did you get to know about us?</P>
                                <FormControl variant="outlined">
                                    <Select
                                        value={know}
                                        onChange={(e) => setKnow(e.target.value)}
                                        className={classes.root}
                                        name="know"
                                    >
                                        <MenuItem value={"0"} disabled>
                                            Please select
                                        </MenuItem>
                                        <MenuItem value={"Google"}>Google</MenuItem>
                                        <MenuItem value={"Linkedin"}>
                                            Linkedin
                                        </MenuItem>
                                        <MenuItem value={"Instagram"}>
                                            Instagram
                                        </MenuItem>
                                        <MenuItem value={"Twitter"}>
                                            Twitter
                                        </MenuItem>
                                        <MenuItem value={"Referral"}>
                                            Referral
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Forms>
                        </Grid>

                        <Forms>
                            <P>Message (optional)</P>
                            <Textarea
                                rows="4"
                                placeholder="Message"
                                name="message"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setValues({ ...values, message: e.target.value })
                                }}
                            />
                        </Forms>

                        <CheckDiv>
                            <Checkbox
                                name="keep_me_updated"
                                defaultChecked={false}
                                color="default"
                            />
                            <p style={{marginBottom:"0px"}}>
                                Please keep me updated in DeepGears products and
                                services
                            </p>
                        </CheckDiv>
                        <Psmall>
                            By clicking 'Talk with us' you confirm that you agree to
                            the processing of your personal data as described in our
                            Privacy Statement. You can revoke your consent at any
                            time you wish.
                        </Psmall>
                        <CtaButton onClick={submitForm} type="submit">
                            Talk with us
                        </CtaButton>
                    </Container>
                </form>

                {open && <ModalComp />}
            </WrapperDiv>
            {/* {props.text && <HeroSection />} */}
        </>
    );
};

export default Sales;
