import React, { useRef, useEffect } from 'react'
import { animateGsap } from '../gsap/animation'
import "../styles/intergration.scss"

export default function Product() {
    const animateRef = useRef(null)
    useEffect(() => {
        animateGsap(animateRef)
    }, [])
    return (
        <div id="intergration" className="product" ref={animateRef}>
            <div className="row mx-0">
                <div className="col-md-6">
                    <h3>State-of-the-art technology at the core of our product</h3>
                    <h6>We have developed a proprietary algorithm able to digitize users’ body from a single video at high accuracy on the their key body measurements</h6>

                    <div className="icon-group-container">
                        <div className="d-flex icon-wrapper">
                            <div className="single-icon last-icon">
                                <div className="text-title">95+ %</div>
                                <div className="icon-heading h5" style={{ borderColor: "#6FCF97" }}>Accuracy on key body measurements</div>
                                <div className="description h6">Our algorithms  detect how users look like and recommend the right size guiding them throughout the purchase</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 video-part">
                    <div className="video-wrapper">
                        <video src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/manual_render_avatar_0001-0250.mp4" autoPlay muted loop></video>
                    </div>
                </div>
            </div>
        </div>
    )
}
