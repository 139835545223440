import React from "react";
import Ourcareers from "../components/Ourcareers/index";
import HeroSection from "../components/Hero/HeroSection";

const OurCareers = () => {
    return (
        <>
            <Ourcareers />
            <HeroSection />
        </>
    );
};

export default OurCareers;
