import React from 'react'
import { useSelector, useDispatch } from "react-redux"
import { setAnimation } from '../../../model/DemoProcess'
import { Template } from './Template'
import { endpoints } from '../config'

export default function Animation() {
    const garmentTemplate = useSelector(state => state.demoProcess.garmentTemplate)
    const cameraView = useSelector(state => state.demoProcess.cameraView)
    const data = {
        garmentTemplate: garmentTemplate?.title,
        cameraView: cameraView?.title
    }
    const dispatch = useDispatch()
    const handleClick = (text) => {
        dispatch(setAnimation(text))
    }
    return <><Template data={data} url={endpoints.allAnimation} handleClick={handleClick} name="animation" hasVideo={true} /></>
}

