import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    margin-bottom: 6.5rem;

    @media ${(props) => props.theme.breakpoints.sm} {
        flex-direction: column;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        margin-bottom: 0;
    }
`;

export const Div = styled.div`
    margin-right: 3rem;
    margin-top: 2rem;

    @media ${(props) => props.theme.breakpoints.md} {
        margin-right: 1.5rem;
    }

    @media ${(props) => props.theme.breakpoints.sm} {
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 3rem;
    }

    & h1 {
        margin: 2rem 0;

        @media ${(props) => props.theme.breakpoints.sm} {
            margin-top: 0;
        }
    }

    & p {
        font-size: 3rem;
        margin-bottom: 2rem;
    }
`;

export const Buttons = styled.div`
    display: flex;

    @media screen and (max-width: 450px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const Button1 = styled.button`
    background-color: #0e4da4;
    color: #fff;
    border: none;

    margin: 2rem 2rem 0 0;
    padding: 1.5rem 0;
    width: 21.5rem;
    border-radius: 5px;
    font-size: 2rem;
    cursor: pointer;
    font-family: "myriad";
    font-weight: 400;

    transition: all 0.2s;

    @media ${(props) => props.theme.breakpoints.xxs} {
        width: 18rem;
    }

    @media screen and (max-width: 450px) {
        width: 100%;
    }

    &:hover {
        transform: scale(1.04);
    }
`;

export const Button2 = styled.button`
    background-color: #fff;
    color: #0e4da4;
    border: 2px solid #0e4da4;
    border-radius: 5px;
    font-size: 2rem;

    margin: 2rem 0 0 0;
    padding: 1.3rem 0;
    width: 21.5rem;
    cursor: pointer;
    font-family: "myriad";
    font-weight: 400;

    transition: all 0.2s;

    &:hover {
        transform: scale(1.04);
    }

    @media ${(props) => props.theme.breakpoints.xxs} {
        width: 18rem;
        margin-right: 0;
    }

    @media screen and (max-width: 450px) {
        width: 100%;
    }
`;

export const Img = styled.img`
    width: 60rem;
    height: 45rem;

    @media ${(props) => props.theme.breakpoints.md} {
        width: 50rem;
        height: 35rem;
    }

    @media ${(props) => props.theme.breakpoints.sm} {
        align-self: center;
        width: 60rem;
        height: 45rem;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        width: 50rem;
        height: auto;
    }

    @media ${(props) => props.theme.breakpoints.xxs} {
        width: 40rem;
    }

    @media screen and (max-width: 350px) {
        width: 32.5rem;
    }
`;

export const Clients = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;

    @media ${(props) => props.theme.breakpoints.sm} {
        flex-wrap: wrap;
    }
`;

export const Client = styled.img`
    width: 20rem;
    height: auto;

    @media ${(props) => props.theme.breakpoints.sm} {
        margin-bottom: 2rem;
    }
`;
