import { createGlobalStyle } from "styled-components";
import { ThemeProvider } from "styled-components";
import Myriad_light from "../fonts/MyriadPro-Light.otf";
// import Myriad_bold from "../fonts/MYRIADPRO-BOLD.otf";
// import Myriad_semibold from "../fonts/MYRIADPRO-SEMIBOLD.otf";
import Myriad_regular from "../fonts/MYRIADPRO-REGULAR.otf";
import Andada from "../fonts/andada.otf";
import theme from "../BreakPoints";

const GlobalStyles = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


@font-face {
  font-family: 'myriad';
  src:  url(${Myriad_light}) format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'myriad';
  src:  url(${Myriad_regular}) format('opentype');
  font-weight: 400;
}



@font-face {
  font-family: 'andada';
  src: local('andada'), url(${Andada}) format('opentype')
}


body, button, input {
    font-family: 'myriad';
}

html {
        font-size: 62.5%; // 62.5% font-size means 1rem = 10px

        @media ${(props) => props.theme.breakpoints.lg} {
        font-size: 50%;
    }
}

a {
    text-decoration: none;
    color: inherit;

}

p, a, li {
    font-family: "myriad";
        font-weight: 300;
}

// Headings
h1 {
    font-size: 4.5rem;
    font-family: "myriad";
        font-weight: 600;
}

// Sub-Headings
h2 {
    font-size: 3.8rem;
    font-family: "myriad";
        font-weight: 600;
}

// Normal text
p {
    font-size: 2rem;
    font-family: "myriad";
        font-weight: 300;
}



`;

export const Layout = ({ children }) => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                {children}
            </ThemeProvider>
        </>
    );
};
