import React from "react";
import styled from "styled-components";
import { CtaButton } from "../components/Reusable/styles";
import vision1 from "../assets/vision1.png";
import vision2 from "../assets/vision2.png";
import vision3 from "../assets/vision3.png";
import vision4 from "../assets/vision4.png";
import { Link } from "react-router-dom";
import HeroSection from "../components/Hero/HeroSection";
import { Helmet } from "react-helmet-async";

const Wrapper = styled.div`
    padding: 12rem 5rem 6rem 5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 12rem 2.5rem 3rem 2.5rem;
    }
`;

const Div = styled.div`
    text-align: center;

    & h1 {
        margin: 2rem 0;
        font-size: 4.5rem;

        @media ${(props) => props.theme.breakpoints.sm} {
            margin-top: 0;
        }
    }

    & h2 {
        margin-bottom: 2.25rem;
        font-size: 3.8rem;
        font-weight: 300;
    }

    & p {
        margin-bottom: 2.25rem;
        font-size: 2rem;
    }

    & button {
        width: 18rem;
        padding: 1rem 0;
    }
`;

const H1 = styled.h1`
    text-align: center;
    margin-top: 8rem;
    font-size: 3.8rem;
`;

const Group = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5rem;
    grid-row-gap: 5rem;

    @media ${(props) => props.theme.breakpoints.sm} {
        display: flex;
        flex-direction: column;
    }
`;

const GroupDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;

    & img {
        width: 20rem;
        height: auto;
        margin-bottom: 2rem;
    }

    & h2 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    & p {
        font-size: 2rem;
        text-align: center;
        width: 65%;

        @media ${(props) => props.theme.breakpoints.sm} {
            width: 100%;
        }
    }
`;

const Team = () => {
    return (
        <>
            <Helmet>
                <title>Team | DeepGears</title>
                <meta
                    name="description"
                    content="Want to join us and help us fulfill our vision? View our open positions!"
                />
                <link rel="canonical" href="/Team" />
            </Helmet>

            <Wrapper>
                <Div>
                    <h1>The vision</h1>
                    <h2>Advance shopping experiences through 3D technology</h2>
                    <p>
                        Want to join us and help us fulfill our vision? View our
                        open positions!
                    </p>
                    <Link to="/OurCareers">
                        <CtaButton>Go to Careers</CtaButton>
                    </Link>
                </Div>

                <H1>Our Values</H1>
                <Group>
                    <GroupDiv>
                        <img src={vision1} alt="vision" />
                        <h2>We are One</h2>
                        <p>
                            We are genuine, open and supportive among each other
                        </p>
                    </GroupDiv>

                    <GroupDiv>
                        <img src={vision2} alt="vision" />
                        <h2>We don't fear being accountable</h2>
                        <p>
                            We engage and take responsibility and ownership for
                            what we do to deliver amazing results
                        </p>
                    </GroupDiv>

                    <GroupDiv>
                        <img src={vision3} alt="vision" />
                        <h2>We create expectional experiences</h2>
                        <p>
                            We commit to create exceptional experiences for our
                            colleagues, our clients and our investors
                        </p>
                    </GroupDiv>

                    <GroupDiv>
                        <img src={vision4} alt="vision" />
                        <h2>We never give up</h2>
                        <p>
                            Innovating can be difficult at times, but only
                            resilience in hard times will lead to exceptional
                            results
                        </p>
                    </GroupDiv>
                </Group>
            </Wrapper>

            <HeroSection />
        </>
    );
};

export default Team;
