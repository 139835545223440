import { configureStore } from "@reduxjs/toolkit";
import modelReducer from "./index";
import DemoProcessSlice from "./DemoProcess"

const store = configureStore({
    reducer: {
        model: modelReducer,
        demoProcess: DemoProcessSlice,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
