import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
    padding: 6rem 5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;

    & h2 {
        margin-bottom: 6rem;
        text-align: center;

        @media ${(props) => props.theme.breakpoints.sm} {
            text-align: left;
            margin-bottom: 2rem;
        }
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 6rem 2.5rem;
    }
`;

export const Div = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 12.5rem;

    @media ${(props) => props.theme.breakpoints.sm} {
        display: flex;
        flex-direction: column;
    }

    & div {
        margin-top: 4rem;
    }

    & img {
        width: 50rem;
        height: auto;

        @media ${(props) => props.theme.breakpoints.sm} {
            align-self: center;
        }

        @media ${(props) => props.theme.breakpoints.xs} {
            width: 40rem;
        }

        @media screen and (max-width: 350px) {
            width: 32.5rem;
        }
    }

    & p {
        margin-bottom: 3.5rem;
    }
`;

export const Symbols = styled.div`
    display: flex;

    @media ${(props) => props.theme.breakpoints.sm} {
        margin-bottom: 4rem;
    }

    & img.img1 {
        margin-right: 5rem;
        width: 12.5rem;
        height: 5rem;
    }

    & img.img2 {
        width: 8rem;
        height: 4.5rem;
    }
`;

export const Div2 = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;

    & h3 {
        font-size: 3rem;
        text-align: center;
        font-family: "myriad";
        font-weight: 400;
        margin-bottom: 5rem;
        padding: 0 5rem;

        @media ${(props) => props.theme.breakpoints.xs} {
            padding: 0 2.5rem;
        }
    }
`;

export const Vids = styled.div`
    padding: 0 5rem;
    display: flex;
    justify-content: space-between;

    @media ${(props) => props.theme.breakpoints.sm} {
        flex-direction: column;
        align-items: center;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 0 2.5rem;
    }

    & video {
        border-radius: 5px;

        @media ${(props) => props.theme.breakpoints.sm} {
            margin-bottom: 2.5rem;
            width: 100%;
        }
    }
`;

export const OrgDiv = styled.div`
    margin-top: 6rem;

    & div.title {
        background-color: #f3f6fa;
        padding: 1.5rem;
        display: flex;
        justify-content: center;
        margin-bottom: 5rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 1300px;
    }

    & h2 {
        font-size: 3rem;
        font-weight: 400;

        @media ${(props) => props.theme.breakpoints.sm} {
            text-align: center;
        }
    }
`;

export const Slider = styled.div`
    height: 20rem;
    margin: auto;
    position: relative;
    width: 90%;
    display: grid;
    place-items: center;
    overflow: hidden;
`;

export const AutoPlay = keyframes`
0% {
    transform: translateX(0);
}
100% {
    transform: translateX(calc(-25rem * 8))
}
`;

export const OrgGrid = styled.div`
    padding: 0 5rem 3rem 5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    animation: ${AutoPlay} 20s linear infinite;

    display: grid;
    width: calc(25rem * 16);

    display: grid;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 2rem;
    align-items: center;
    justify-items: center;

    @media ${(props) => props.theme.breakpoints.sm} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 2.5rem 3rem 2.5rem;
    }
`;

export const Org = styled.img`
    width: 13.5rem;
    height: auto;
    margin-right: 2rem;

    @media ${(props) => props.theme.breakpoints.sm} {
        margin-bottom: 2rem;
    }
`;
