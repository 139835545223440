import React from "react";
import { Button, GridStats, Wrapper } from "./styles";

const Impact = () => {
    return (
        <Wrapper>
            <h2>Our Impact</h2>
            <GridStats>
                <div>
                    <span>+25%</span>
                    <p>
                        Increased conversion <br /> for items virtually tried on
                    </p>
                </div>
                <div>
                    <span>+40%</span>
                    <p>Reduction in returns</p>
                </div>
                <div>
                    <span>+300%</span>
                    <p>
                        Time spent on
                        <br /> DeepGears-powered products
                    </p>
                </div>
            </GridStats>
            <Button>Find out more</Button>
        </Wrapper>
    );
};

export default Impact;
