import React from "react";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Impact from "../components/ImpactSection/Impact";
import Integration from "../components/IntegrateSection/index";
import Lookfit from "../components/Look&fitSection/index";
import { Button, Hero, Shape, Shape2 } from "../components/Hero/styles";
import Video from "../components/Video";
import { Helmet } from "react-helmet-async";

const Btn1 = styled.button`
    width: 35rem;
    margin-top: -10rem;
    padding: 1rem 3rem;
    background: #fff;
    border: 2px solid #fff;
    color: #0e4da4;
    border-radius: 35px;
    font-size: 2.5rem;
    cursor: pointer;
    margin-right: 4rem;
    transition: all 0.2s;

    @media ${(props) => props.theme.breakpoints.sm} {
        margin-right: 0;
        margin-bottom: 2rem;
    }

    &:hover {
        transform: scale(1.04);
    }
`;

const Btn2 = styled(Button)`
    width: 35rem;
    text-align: center;
    transition: all 0.2s;

    &:hover {
        transform: scale(1.04);
    }

    @media ${(props) => props.theme.breakpoints.sm} {
        margin: 0;
    }
`;

const HomePage = () => {
    return (
        <div>
            <Helmet>
                <title>
                    DeepGears - Unlock virtual try-ons for your customers
                </title>
                <meta
                    name="description"
                    content="Enable virtual try-ons for your online shoppers with our product"
                />
                <link rel="canonical" href="/" />
            </Helmet>

            <Header />
            <Video />
            <Lookfit />
            <Impact />
            <Integration />
            <Hero>
                <Shape>
                    <h1>Bring your online marketplace to a whole new level</h1>
                </Shape>
                <Shape2>
                    <Btn1>Try one item for free</Btn1>
                    <Btn2>
                        <a
                            href="https://www.shopify.co.uk/blog/3d-models-video"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Virtual try-on case study
                        </a>
                    </Btn2>
                </Shape2>
            </Hero>
        </div>
    );
};

export default HomePage;
