import React from 'react'
import { useSelector, useDispatch } from "react-redux"
import { setCameraView } from '../../../model/DemoProcess'
import { Template } from './Template'
import { endpoints } from '../config'

export default function Camera() {
    const garmentTemplate = useSelector(state => state.demoProcess.garmentTemplate)
    const animation = useSelector(state => state.demoProcess.animation)
    const data = {
        garmentTemplate: garmentTemplate?.title,
        animation: animation?.title
    }
    const dispatch = useDispatch()
    const handleClick = (text) => {
        dispatch(setCameraView(text))
    }
    return <><Template data={data} url={endpoints.allCameraView} name="camera_view" handleClick={handleClick} hasVideo={true}  /></>
}
