import styled from "styled-components";

export const Hero = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
`;

export const Shape = styled.div`
    width: 100%;
    height: 30rem;
    background-color: #0e4da4;
    padding-top: 8rem;

    @media ${(props) => props.theme.breakpoints.sm} {
        padding: 8rem 5rem 0 5rem;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 4rem 1rem 0 1rem;
    }

    & h1 {
        color: #fff;
        text-align: center;
        font-size: 5rem;
        font-family: "myriad";
        font-weight: 200;

        @media ${(props) => props.theme.breakpoints.sm} {
            font-size: 4rem;
        }
    }
`;

export const Shape2 = styled.div`
    margin-top: -10.5rem;
    width: 100%;
    height: 20rem;
    background-color: #0e4da4;
    border-radius: 600px / 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${(props) => props.theme.breakpoints.sm} {
        flex-direction: column;
    }
`;

export const Button = styled.div`
    margin-top: -10rem;
    padding: 1rem 3rem;
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    border-radius: 35px;
    font-size: 2.5rem;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
        transform: scale(1.04);
    }
`;
