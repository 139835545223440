import { handlePost, endpoints } from "../config.js"
export const allGarmentTemplates = (userData) => {
    let data = userData.data
    return async (dispatch) => {
        let result = await handlePost({ url: userData.url, data: data })
        if (result) {
            return result
        }
    }
}
