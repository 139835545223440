import React from "react";
import Cta from "../homePagev.2/components/Cta";
import { Button, Hero, Shape, Shape2 } from "./styles";

const HeroSection = () => {
    return (
        <Cta></Cta>
    );
};

export default HeroSection;
