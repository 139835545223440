import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 12rem 5rem 6rem 5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;

    &.header {
        padding: 12rem 5rem 0 5rem;

        @media ${(props) => props.theme.breakpoints.xs} {
            padding: 12rem 2.5rem 6rem 2.5rem;
        }
    }

    &.privacy {
        background: rgb(255, 255, 255);
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(14, 77, 164, 0.2) 100%
        );
    }

    &.impact {
        background-color: #f4f6fa;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 12rem 2.5rem;
    }
`;

// XsText  = small text
export const XsText = styled.p`
    font-size: 1.6rem;
    font-family: "myriad";
    font-weight: 300;
`;

export const Logo = styled.img`
    width: 15rem;
    margin-right: 6rem;

    @media ${(props) => props.theme.breakpoints.md} {
        margin-right: 4rem;
    }
`;

export const Button = styled.button`
    background-color: #fff;
    color: #0e4da4;
    padding: 0.7rem 1.5rem;
    border: 2px solid #0e4da4;
    border-radius: 5px;
    font-size: 2rem;
    font-weight: 600;

    margin-right: 1.2rem;
    cursor: pointer;
    font-family: "myriad";
    font-weight: 400;

    transition: all 0.2s;

    &:hover {
        transform: scale(1.04);
    }
`;

export const CtaButton = styled.button`
    // CtaButton = Call to action button
    background-color: #0e4da4;
    color: #fff;
    padding: 0.7rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 2rem;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s;
    font-family: "myriad";
    font-weight: 400;

    &:hover {
        opacity: 0.9;
    }
`;

export const WhiteBtn = styled.div`
    background-color: #fff;
    color: #0e4da4;
    border: none;
    border-radius: 2rem;
    font-weight: 700;
    font-size: 2rem;
    padding: 0.8rem 5rem;
    width: fit-content;
    align-self: center;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        transform: scale(1.04);
    }
`;

export const Forms = styled.div`
    & .MuiInputBase-root {
        font-size: 1.5rem;
        height: 4.5rem;
        font-family: myriad, serif;
        font-weight: 300;
        border-radius: 10px;
    }

    & .MuiFormControl-root {
        width: 100%;
    }
`;
