import React, { useState } from 'react'
import "./styles/Sidebar.scss"
import { useDispatch,useSelector } from 'react-redux'
import { setResultPaneOpen,setCurrentResultUrl } from "../../model/DemoProcess"
import { useWindowSize } from 'react-window-size-hooks';
import { mobileWidth, smallHeight } from '../breakpoints/breakpoint';
import classnames from "classnames"
export default function SideBar() {
    const panelOpen = useSelector((state) => state.demoProcess.resultPaneOpen)
    const { height,width } = useWindowSize();
    const [widgetList, setwidgetList] = useState([
        {
            image: "https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Dress.png",
            text: `Garment<br/>
            template`,
            url: "/garmentTemplate",
            active:true
        },
        {
            image: "https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Exercise.png",
            text: "Animation",
            url: "/animation",
            active: false
        },
        {
            image: "https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Documentary.png",
            text: "Camera",
            url: "/camera",
            active: false
        },
        // {
        //     image: "https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Paint+Palette.png",
        //     text: "Background",
        //     url: "/background",
        //     active: false
        // },
        {
            image: "https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Musical+Notes.png",
            text: "Audio",
            url: "/audio",
            active: false
        },
    ])
    const fixedOrNot=()=>{
        if((height <= smallHeight) && (width <= mobileWidth)){
            return true
        }
        else{
            return false
        }
    }
    return (
        <div id="demo-process-sidebar" className={classnames({ "fixed-sidebar": fixedOrNot() })}>
            {widgetList.map((item,idx)=>{
                return <SideBarButton image={item.image} text={item.text} active={item.active} state={widgetList} setState={setwidgetList} id={idx} url={item.url} key={idx}/>

            })}
        </div>
    )
}
function SideBarButton({ image, text, active,state,setState,id,url }) {
    const dispatch = useDispatch()
    const { height,width } = useWindowSize();
    const handleClick=()=>{
        dispatch(setResultPaneOpen(true))
        dispatch(setCurrentResultUrl(url))
        let copyState=[...state]
        copyState.forEach((item,idx)=>{
            if(idx===id){
                item.active=true
            }
            else{
                item.active=false
            }
        })
        setState(copyState)
        if ((height <= smallHeight) && (width <= mobileWidth)){
            dispatch(setResultPaneOpen(true))
        }
    }
    return <div className={`sidebar-icon ${active && "active"}`} key={id} onClick={handleClick}>
        <div className="icons">
            <img src={image} alt="" />
            <div className="text" dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
    </div>
}

