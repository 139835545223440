import React from "react";
import { Block, Icon } from "./styles";
import { RiCheckLine } from "react-icons/ri";
import "./modal.scss"
const ModalComp = () => {
    return (
        <Block className="icon-modal">
            <Icon sx={{minWidth:"50px",minHeight:"50px"}}>
                <RiCheckLine />
            </Icon>
            <p>Thank you for your enquiry!</p>
        </Block>
    );
};

export default ModalComp;
