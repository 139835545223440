import React from 'react'
import SideBarManager from './SideBars/SideBarManager'
import GarmentTemplates from './SideBars/Template'
import Animation from './SideBars/Animation'
import Size from './SideBars/Camera'
import Background from './SideBars/Background'
import Audio from './SideBars/Audio'

export default function Result() {
    return (
        <>
            <SideBarManager path="/garmentTemplate">
                <GarmentTemplates />
            </SideBarManager>
            <SideBarManager path="/animation">
                <Animation />
            </SideBarManager>
            <SideBarManager path="/camera">
                <Size />
            </SideBarManager>
            <SideBarManager path="/background">
                <Background />
            </SideBarManager>
            <SideBarManager path="/audio">
                <Audio />
            </SideBarManager>
        </>
    )
}
