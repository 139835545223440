import React from "react";
import styled from "styled-components";
import finalvideo from "../assets/Final.mp4";

const Div = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
`;

const Video = () => {
    return (
        <Div>
            <video controls width={"100%"} autoPlay playsinline>
                <source src={finalvideo} />
            </video>
        </Div>
    );
};

export default Video;
