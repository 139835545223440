import React, { useState,useEffect,useRef } from "react";
import logo from "../../logo.svg";
import ReactGA from "react-ga";

import { slide as Menu } from 'react-burger-menu'
import { Link,useHistory } from "react-router-dom";

import "./navbar.scss"
import "./burger.css"

import Dropdown from 'react-multilevel-dropdown';



const Navbar = () => {
    const history=useHistory()
    useEffect(() => {
        document.querySelector('bm-overlay')?.addEventListener("click",function(){
            document.querySelector("#react-burger-cross-btn").click()
        })
    }, [])
    
    const ref=useRef(null)
    const handleHover=(refObj)=>{
        const reference = refObj.current.dropdownRef?.current
        reference.addEventListener('mouseleave',(e)=>{
            reference.click()
            refObj.current.isOpen=false
            refObj.current.toggle()
        })
    }
    useEffect(() => {
        handleHover(ref)
    }, [])
    

    return (
        <div className="container-fluid" id="navbar">
            <div className="container">
                <div className="logo">
                    <Link to="/"><img src={logo} alt="" /></Link>
                </div>
                <div className="links">
                    <div className="link-item"><Link to="/">Home</Link> <div className="line"></div> </div>
                    <div className="link-item" >
                        <Dropdown
                            title='Virtual fitting room'
                            openOnHover={true}
                            isopen={true}
                            ref={ref}
                        >
                            <Dropdown.Item onClick={() => history.push("/sizing-and-fitting")}>
                                Sizing & Fitting
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => history.push("/virtual-fitting-room")}>
                                Virtual fitting room
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => history.push("/deep-gears-studio")}>
                                DeepGears Studio
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                    <div className="link-item"><Link to="/blog">Blog</Link> <div className="line"></div> </div>
                </div>
                <div className="cta">
                    <Link to="/Sales"><div className="btn btn-default talk-to-sales">
                        Contact Us
                    </div></Link>
                </div>
                <div className="burger-menu">
                    <Menu right >
                        <div className="links-container">
                            <div className="link-item"><Link to="/">Home</Link> <div className="line"></div> </div>
                            <div className="link-item"><Link to="/sizing-and-fitting">Sizing & Fitting</Link></div>
                            <div className="link-item"><Link to="/virtual-fitting-room">Virtual fitting room</Link> </div>
                            <div className="link-item"><Link to="/deep-gears-studio">DeepGears Studio</Link> </div>
                            <div className="link-item"><Link to="/Team">Vision</Link> </div>
                            <div className="link-item"><Link to="/Careers">Careers</Link> </div>
                            <div className="link-item"><Link to="/Referral">Referral</Link> </div>
                            <div className="link-item"><Link to="/blog">Blog</Link> <div className="line"></div> </div>
                            <div className="link-item"><Link to="/Privacy">Privacy Policy</Link> <div className="line"></div> </div>
                        </div>
                        <div className="cta">
                            <Link to="/Sales"><div className="btn btn-default talk-to-sales">
                                Contact Us
                            </div></Link>
                        </div>
                    </Menu>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
