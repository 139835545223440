import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid'


const DemoProcessSlice = createSlice({
    name: "Model",

    initialState: {
        resultPaneOpen: true,
        currentResultUrl: "/garmentTemplate",
        showBasket: false,
        checkout: false,
        garmentTemplate: {
            title: null,
            price: 0
        },
        animation: {
            title: "walkforward",
            price: 0
        },
        cameraView: {
            title: "frontal",
            price: 0
        },
        size: null,
        video: "https://deep-gears-storage.s3.eu-west-2.amazonaws.com/3D_canva/garment_animations/1",
        hasvideo: false,
        optionChoosen: -1,
        asset_link: null,
        email: null,
        s3_asset_location: null,
        amount: 0,
        uploading: 0,
        userId: uuidv4(),
        filesToUpload: null,
        background: { hex: "#000000" }
    },

    reducers: {
        setResultPaneOpen: (state, action) => {
            state.resultPaneOpen = action.payload;
        },
        setCurrentResultUrl: (state, action) => {
            state.currentResultUrl = action.payload;
        },
        setShowBasket: (state, action) => {
            state.showBasket = action.payload;
        },
        setCheckout: (state, action) => {
            state = { ...state, checkout: action.payload }
            return state
        },
        setGarmentTemplate: (state, action) => {
            state = { ...state, garmentTemplate: action.payload }
            return state
        },
        setAnimation: (state, action) => {
            state = { ...state, animation: action.payload }
            return state
        },
        setCameraView: (state, action) => {
            state = { ...state, cameraView: action.payload }
            return state
        },
        setBackground: (state, action) => {
            state = { ...state, background: action.payload }
            return state
        },
        setSize: (state, action) => {
            state = { ...state, size: action.payload }
            return state
        },
        setVideo: (state, action) => {
            state = { ...state, video: action.payload }
            return state
        },
        setOptionChoosen: (state, action) => {
            state.optionChoosen = action.payload;
        },
        setAsset_link: (state, action) => {
            state = { ...state, asset_link: action.payload }
            return state
        },
        setEmail: (state, action) => {
            state = { ...state, email: action.payload }
            return state
        },
        setS3_asset_location: (state, action) => {
            state.s3_asset_location = action.payload;
        },
        setAmount: (state, action) => {
            state.amount = action.payload;
        },
        setHasvideo: (state, action) => {
            state.hasvideo = action.payload;
        },
        setUploading: (state, action) => {
            state = { ...state, uploading: action.payload }
            return state
        },
        setFilesToUpload: (state, action) => {
            state = { ...state, filesToUpload: action.payload }
            return state
        }

    },
});

export const { setResultPaneOpen, setCurrentResultUrl, setShowBasket, setCheckout, setGarmentTemplate, setAnimation, setCameraView, setBackground, setSize, setVideo, setHasvideo, setOptionChoosen, setAsset_link, setEmail, setS3_asset_location, setAmount, setUploading, setFilesToUpload } = DemoProcessSlice.actions;

export default DemoProcessSlice.reducer;
