import { createSlice } from "@reduxjs/toolkit";

const modelSlice = createSlice({
    name: "Model",

    initialState: {
        slideInPopup: false,
    },

    reducers: {
        setSlideInPopup: (state, action) => {
            state.slideInPopup = action.payload;
        },
    },
});

export const { setSlideInPopup } = modelSlice.actions;

export default modelSlice.reducer;
