import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles/InPress.scss"

const listOfPress = [
    {
        heading: "/vogue.png",
        date: "Feb 22, 2024",
        desc: "Early data suggests that digital twin fit predictors can increase customer confidence and decrease returns. Deepgears, which provided the tech for the YNAP pilot, says the brands that have used its tech see an average 25 per cent decrease in return rates and a 28 per cent in conversion on the items that offer the digital mannequin option",
        press: "/vogue.svg",
        link: "https://www.voguebusiness.com/story/technology/want-to-reduce-returns-avatars-might-be-the-answer?fbclid=PAAaaZfLt3EkOXD0CtZzfork3cVB9OGEf1fRPaszPe8WOSJfD-AmoPNE2fNjQ_aem_AVqKTYHZT1qy8jjp-z_3qDpRSE_zfu4FPLw7rFJSuC7TiL42vxrTpK3_SddI4mIvMtQ"
    },
    {
        heading: "/fashionunited.png",
        date: "Feb 22, 2024",
        press: "/fashionunited.svg",
        link: "https://fashionunited.com/news/fashion/yoox-net-a-porter-launches-virtual-try-on-pilot-for-mr-p/2024022258531"
    },
    {
        heading: "/fashionnetwork.png",
        date: "Feb 22, 2024",
        press: "/fashionnetwork.svg",
        link: "https://ww.fashionnetwork.com/news/Ynap-rolls-out-virtual-try-on-for-mr-porter-s-own-brand,1607462.html"
    },
    {
        heading: "/luxurydaily.png",
        date: "Feb 22, 2024",
        press: "/luxurydaily.svg",
        link: "https://www.luxurydaily.com/yoox-net-a-porter-elevates-mr-porter-with-virtual-try-on-pilot/"
    }
]

const Component = () => {
    return <div id="in-press">
        <div className="grid">
            <a href={listOfPress[0].link} target="_blank" rel="noopener noreferrer">
                <div className="vogue-card">
                    <div>
                        <img src={listOfPress[0].press} alt="vogue logo" class="vogue" />
                        <div className="flex">
                            <div className="center"><img src={listOfPress[0].heading} alt="" className="title-snap" /></div>
                            <div></div>
                        </div>
                        <div className="desc">{listOfPress[0].desc}</div>
                    </div>
                </div>
            </a>
        </div>
        <div className="second-grid">
            {
                listOfPress.map((press, index) => {
                    return index !== 0 && <SingleSideCard {...press} key={index} />
                })
            }
        </div>
    </div>

}

const SingleSideCard = (props) => {
    return (<a className="single-card" href={props.link} target="_blank" rel="noopener noreferrer">
        <div className="logo">
            <img src={props.press} alt="" />
        </div>
        <div className="center">
            <img src={props.heading} alt="" className="title-snap-1" />
        </div>
    </a>)
}

export default Component;