import React, { useRef, useEffect } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./style/Pitch.scss"

export default function Pitch(props) {
  const { reverse, title, desc }=props
  const containerRef = useRef()
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.fromTo(containerRef.current, {
      opacity: 0.1
    }, {
      opacity: 1, duration: 1,
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top center",
        end: "bottom center",
        scrub: true
      }
    })
  }, [])

  return (
    <div className="container-fluid" id="pitch" ref={containerRef}>
      <div className={`row ${reverse && "flex-row-reverse"}`}>
        <div className="col-md-6 img-section p-3">
          {props.video ? <video src={props.video} alt="" className='image' autoPlay muted loop/>
          :<img src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/image+387.png" alt="" className='image' />}
        </div>
        <div className="col-md-6 text-section">
          <div>
            <h1>{title}</h1>
            <h4>{desc}</h4>
          </div>
        </div>
      </div>
    </div>
  )
}
