import React,{useRef,useEffect} from 'react'
import choice from "../../../assets/about/Multiple Choice.png"
import vision from "../../../assets/about/Vision.png"
import { animateGsap } from '../gsap/animation'
import "../styles/intergration.scss"

export default function Features() {
    const animateRef = useRef(null)
    useEffect(() => {
      animateGsap(animateRef)
    }, [])
    
    return (
        <div id="intergration" className='feature' ref={animateRef}>
            <div className="row mx-0">
                <div className="col-md-6">
                    <h3>Less than 60 seconds to create a personalized 3D avatar</h3>
                    <h6>No lenghty sign-ups or registration required, users are immediately prompted to create their personal avatar </h6>

                    <div className="icon-group-container">
                        <div className="d-md-flex icon-wrapper">
                            <div className="single-icon">
                                <img src={choice} alt="" />
                                <div className="icon-heading h5" style={{ borderColor: "#6FCF97" }}>Maximum flexibility</div>
                                <div className="description h6">Two ways to create an avatar, depending on users’ preference</div>
                            </div>
                            <div className="single-icon">
                                <img src={vision} alt="" />
                                <div className="icon-heading h5" style={{ borderColor: "#0E4DA4" }}>Advanced computer vision</div>
                                <div className="description h6">With a just video, users will be able to create their personal avatar with their very same body measurements</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 video-part">
                    <div className="video-wrapper">
                        <video src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/video+2+Faceless.mp4" autoPlay muted loop></video>
                    </div>
                </div>
            </div>
        </div>
    )
}
