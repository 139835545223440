import styled from "styled-components";

export const Block = styled.div`
    background-color: #fff;
    width: 20rem;
    height: 20rem;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 1rem;
    position: fixed;
    text-align: center;
    top: 50%;
    left: 50%;
    margin-top: -100px; /* Negative half of height. */
    margin-left: -100px;
    /* display: none; */
`;

export const Icon = styled.div`
    border: 3px solid #00c666;
    border-radius: 50%;
    height: 50px;
    width: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 6rem;
    color: #00c666;
    margin-bottom: 2rem;
`;
