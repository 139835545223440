import React from 'react'
import "./styles/university.scss"
import reims from "../../../assets/reims.png"
import microsoft from "../../../assets/microsoft.png"
import google from "../../../assets/google.png"
import epl from "../../../assets/epfl.png"
import apx from "../../../assets/apx.png"
import aws from "../../../assets/aws.png"

export default function Universities() {
  return (
    <div className="container-custom">
          <div id="universities">
              <div className="row top-text">
                  <div className="col-md-12 justify-content-center">
                      <div className="text-uppercase text-size-3 d-flex justify-content-center pow-text">Powered by a team from</div>
                      <div className='impact-text text-size-4 text-capitalize d-flex justify-content-center '>The best universities and supported by<br /> world-leading organizations</div>
                  </div>
              </div>
              <div className="cards">
                  <SingleCard img={reims} />
                  <SingleCard img={microsoft} />
                  <SingleCard img={google} />
                  <SingleCard img={epl} />
                  <SingleCard img={apx} />
                  <SingleCard img={aws} />
              </div>
          </div>
    </div>
  )
}
function SingleCard({img}) {
    return <div className="single-uni-card">
        <img src={img} alt="" />
    </div>
}

