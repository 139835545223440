import React from 'react'
import Footer from '../components/Demo/Footer'
import Header from '../components/Demo/Header'
import Pitch from '../components/Demo/Pitch'
import "./styles/demoMobile.scss"

export default function Demo() {
  return (
    <div style={{ background: "black" }} id="demo-mobile">
      <Header />
      <Pitch reverse={false} title="1. Choose your  garment template" desc="Browse our garment termplate inventory and familiarize with our unique 3D solutions" video="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/Screen+Recording+2022-08-17+at+16.52.59.mov" />
      <div id="protrait">
        <Pitch reverse={true} title="2. Customize your animation" desc="From traditional catwalks to dancing moves, you can select the animation that makes more sense to you and tailor it to your style" video="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/ezgif.com-gif-maker+(2).mp4" />
      </div>
      <Footer />
    </div>
  )
}
