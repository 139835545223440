import React from "react";
import { Shape2, Wrapper } from "./styles";
import phone from "../../../assets/phone.png";

const Impact = () => {
    return (
        <>
            <Wrapper>
                <img src={phone} alt="phone" />
                <div>
                    <h1>It's not just about the money</h1>
                    <p>
                        We are deeply committed to make a difference to the
                        environment and reduce the unstainable impact of returns
                    </p>
                </div>
            </Wrapper>
            <Shape2>
                <h1>
                    Help us bring innovation and sustainabillity to fashion
                    retailers
                </h1>
            </Shape2>
        </>
    );
};

export default Impact;
