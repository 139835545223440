import styled from "styled-components";
import { Wrapper } from "../Reusable/styles";

export const WrapperDiv = styled(Wrapper)`
    background: rgb(255, 255, 255);
    background: -webkit-linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(14, 77, 164, 0.2) 100%
    );
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(14, 77, 164, 0.2) 100%
    );

    display: flex;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 800px) {
        margin-bottom: 2rem;
    }
`;

export const Title = styled.h2`
    margin-bottom: 4rem;
    font-size: 3.8rem;
    font-weight: 300;
`;

export const Text = styled.p`
    margin-bottom: 2rem;
    font-size: 2.5rem;
`;

export const Container = styled.form`
    padding: 3rem;
    width: 80rem;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 2rem;

    @media screen and (max-width: 800px) {
        width: auto;
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

    margin-bottom: 3rem;

    @media ${(props) => props.theme.breakpoints.xxs} {
        display: flex;
        flex-direction: column;
    }
`;

export const Select = styled.select`
    padding: 1rem;
    border-radius: 5px;
    width: 100%;
    font-size: 1.5rem;
    border: 1px solid #a0a4a8;
`;

export const CheckDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    & input {
        margin-right: 0.5rem;
    }

    & p {
        font-size: 1.5rem;
    }
`;

export const P = styled.p`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
`;

export const Psmall = styled.p`
    margin-bottom: 1rem;
    font-size: 1.2rem;
`;

export const ErrorP = styled.p`
    font-size: 1.25rem;
    margin: 1rem 0 0 1rem;
    color: red;
`;
