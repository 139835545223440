import React, { useEffect, useState, useRef } from "react";
import HeroSection from "../components/Hero/HeroSection";
import styled from "styled-components";
import { CtaButton, Forms } from "../components/Reusable/styles";
import { Helmet } from "react-helmet-async";
import validate from "../components/hooks/validate";
import ModalComp from "../components/modal/Modal";
import Modal from "react-modal";

import { useSelector } from "react-redux"

import { makeStyles } from "@material-ui/core/styles";
import {
    TextField,
    Select,
    Checkbox,
    MenuItem,
    FormControl,
} from "@material-ui/core";
import {
    WrapperDiv,
    Container,
    Info,
    Title,
    Text,
    CheckDiv,
    Psmall,
    P,
    ErrorP,
} from "../components/Ourcareers/styles";
import emailjs from '@emailjs/browser';
import { handlePost, endpoints } from "../components/DemoProcess/config"

// Styles
const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
    },
}));

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    margin-bottom: 3rem;

    @media ${(props) => props.theme.breakpoints.xxs} {
        display: flex;
        flex-direction: column;
    }
`;

const Textarea = styled.textarea`
    width: 100%;
    padding: 1rem;
    font-family: "myriad";
    font-weight: 300;
    resize: none;
    font-size: 1.5rem;
    border: 1px solid #c4c4c4;

    border-radius: 5px;
`;

// Component
const DemoSales = (props) => {
    const classes = useStyles();
    const ref = useRef(null)
    const [values, setValues] = useState({
        fullname: "",
        email: "",
        company: "",
        message: "",
        phone:"",
        digitization: "0"
    });
    const [role, setRole] = useState("0");
    const [know, setKnow] = useState("0");

    const [errors, setErrors] = useState({});
    const [open, setIsOpen] = useState(false);

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const demoProcess = useSelector(state => state.demoProcess)

    const submitForm = (e) => {
        e.preventDefault();
        setErrors(validate(values));

        if ((values.fullname || values.email === "") && values.company === "") {
        } else {
            const handleOpen = () => {
                setIsOpen(true);
                setTimeout(() => {
                    setIsOpen(false);
                }, 3000);
            }
            handleOpen()
            var templateParams = {
                fullname: values.fullname,
                email: values.email,
                company: values.company,
                message: values.message,
                phone: values.phone,
                know: know,
                digitization: values.digitization,
            };
            emailjs
                .send(
                    "tech@deepgears.com",
                    "template_sp633vv",
                    templateParams,
                    "user_5lfoznhROSKNswNIxTTDd"
                )
                .then(
                    async (result) => {
                        if (!props.text) {
                            let data = {
                                music: null,
                                camera_view: demoProcess.cameraView.title
                                ,
                                animation: demoProcess.animation.title,
                                garment_type: demoProcess.garmentTemplate.title,
                                email: values.email
                            }
                            let response = await handlePost({ url: process.env.REACT_APP_API_ROOT + endpoints.storeUserInfo, data: data })
                        }
                    },
                    (error) => {
                    }
                );
            ref.current.reset();
        }
    };

    return (
        <>
            {props.text && (
                <Helmet>
                    <title>Sales | DeepGears</title>
                    <meta
                        name="description"
                        content="Get in touch with us to understand how
                    DeepGears can help you increase your e-comm conversion and reduce returns."
                    />
                    <link rel="canonical" href="/Sales" />
                </Helmet>
            )}

            <WrapperDiv id="wrapper-div">
                {props.text && <Info>
                    <Title>Contact our Sales team</Title>
                    <Text>
                        Get in touch with us to understand how <br />
                        DeepGears can help you increase your e-comm conversion
                        and reduce returns.
                    </Text>

                    <Text>
                        <li>Get pricing information</li>
                        <li>Explore use cases</li>
                        <li>Try our demo</li>
                    </Text>
                </Info>}
                <form ref={ref} onSubmit={submitForm}>
                    <Container >
                        <Grid>
                            <Forms>
                                <P>Full Name *</P>
                                <TextField
                                    placeholder={"Your full name"}
                                    className={classes.root}
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="fullname"
                                    value={values.fullname}
                                    onChange={handleChange}
                                />
                                {errors.fullname && (
                                    <ErrorP>{errors.fullname}</ErrorP>
                                )}
                            </Forms>

                            <Forms>
                                <P>Work email address *</P>
                                <TextField
                                    placeholder={"example@calvinklein.com"}
                                    className={classes.root}
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="email"
                                    type="email"
                                    value={values.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <ErrorP>{errors.email}</ErrorP>}
                            </Forms>

                            <Forms>
                                <P>Phone number *</P>
                                <TextField
                                    placeholder={"+4477123456789"}
                                    className={classes.root}
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                />
                                {errors.company && (
                                    <ErrorP>{errors.company}</ErrorP>
                                )}
                            </Forms>

                            <Forms>
                                <P>Company *</P>
                                <TextField
                                    placeholder={"Calvin Klein"}
                                    className={classes.root}
                                    id="outlined-basic"
                                    variant="outlined"
                                    name="company"
                                    value={values.company}
                                    onChange={handleChange}
                                />
                                {errors.company && (
                                    <ErrorP>{errors.company}</ErrorP>
                                )}
                            </Forms>
                            <Forms>
                                <P>Digitization method *</P>
                                <FormControl variant="outlined">
                                    <Select
                                        value={values.digitization}
                                        onChange={handleChange}
                                        className={classes.root}
                                        name="digitization"
                                    >
                                        <MenuItem value={"0"} disabled>
                                            Please select
                                        </MenuItem>
                                        <MenuItem value={"Share website link to your product / set of pictures"}>Share website link to your product / set of pictures</MenuItem>
                                        <MenuItem value={"Send us your real sample (we will share all the details via email)"}>Send us your real sample (we will share all the details via email)</MenuItem>
                                    </Select>
                                </FormControl>
                            </Forms>
                            <Forms>
                                <P>How did you get to know about us?</P>
                                <FormControl variant="outlined">
                                    <Select
                                        value={know}
                                        onChange={(e) => setKnow(e.target.value)}
                                        className={classes.root}
                                        name="know"
                                    >
                                        <MenuItem value={"0"} disabled>
                                            Please select
                                        </MenuItem>
                                        <MenuItem value={"Google"}>Google</MenuItem>
                                        <MenuItem value={"Linkedin"}>
                                            Linkedin
                                        </MenuItem>
                                        <MenuItem value={"Instagram"}>
                                            Instagram
                                        </MenuItem>
                                        <MenuItem value={"Twitter"}>
                                            Twitter
                                        </MenuItem>
                                        <MenuItem value={"Referral"}>
                                            Referral
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Forms>
                        </Grid>

                        <Forms>
                            <P>Message (optional)</P>
                            <Textarea
                                rows="4"
                                placeholder="Message"
                                name="message"
                                value={values.message}
                                onChange={handleChange}
                            />
                        </Forms>

                        <div className="d-flex justify-content-end w-100 mt-5">
                            <CtaButton onClick={submitForm} type="submit">
                                Ask for a quote
                            </CtaButton>
                        </div>
                    </Container>
                </form>

                {open && <ModalComp />}
            </WrapperDiv>
            {props.text && <HeroSection />}
        </>
    );
};

export default DemoSales;
