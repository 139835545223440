import React, { useState, useCallback } from 'react'
import "./styles/PopUp.scss"
import { Forms } from "../../components/Reusable/styles";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from 'react-dropzone';
import { BasketDetails } from './Video';
import { useDispatch, useSelector } from 'react-redux';
import { setCheckout, setOptionChoosen, setAsset_link, setUploading, setFilesToUpload } from "../../model/DemoProcess"
import { EmptyList } from './SideBars/Template';
import DemoSales from '../../pages/DemoSales';
export default function PopUp() {
    const dispatch = useDispatch()
    const demoProcess = useSelector(state => state.demoProcess)
    const handleClose = () => {
        dispatch(setCheckout(false))
    }
    const [panelOpen, setpanelOpen] = useState(0)
    const height = () => {
        var body = document.body,
            html = document.documentElement;

        var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight)
        return height
    }
    return (
        <div id="pop-up-process" style={{ minHeight: height() }}>
            <div style={{
                minHeight:"100vh",
                minWidth:"100vw",
                display:"grid",
                placeItems:"center"

            }}>
                <div className="process-panel">
                    <div className="d-flex justify-content-between upper-text" style={{ minWidth: "100%" }}>
                        <h2>Digitize your own items</h2>
                        <img src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/back.svg" alt="" onClick={handleClose} />
                    </div>
                    <div className="d-flex payment-elements" style={{ gap: "20px" }}>
                        <DemoSales />
                        <BasketDetails padding={false} close={false} clear={false} checkout={false} />
                    </div>
                </div>
            </div>
        </div>
    )
}
function WebsiteSelection({ text, component, panel, id }) {
    const [state, setstate] = useState(false)
    const dispatch = useDispatch()
    const handleChange = () => {
        dispatch(setOptionChoosen(id))
        setstate(true)
        panel.setpanelOpen(id)
    }
    return (<>
        <div className="form-check">
            <input className="form-check-input" type="radio" name="flexRadioDefault" value={state} id={text} onClick={handleChange} />
            <label className="form-check-label text" for="flexRadioDefault1">
                {text}
            </label>
        </div>
        {(state && panel.panelOpen === id) && <div className="extension">
            {component}
        </div>}
    </>)
}

function SendRealSamples() {
    return (
        <div className="text" style={{ textAlign: "left" }}>
            Please send your merchandise to our headquarters, you will receive it back in 3-5 working days
            <br /><br />
            <span>Flat 4, 129 King's Cross Road, King's Cross Road, London, England, WC1X 9BJ</span>
        </div>
    )
}

function FilesUpload() {
    const dispatch = useDispatch()
    const demoProcess = useSelector(state => state.demoProcess)
    const onDrop = useCallback(acceptedFiles => {
        dispatch(setFilesToUpload(acceptedFiles))
    }, [])
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <div className="file-upload">
            {(demoProcess.uploading) ?
                <><EmptyList link={require("../../assets/uploading.json")} widthStyle="200" /></>
                :
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Drag and drop your files here.</p>
                    {files}
                </div>}
        </div>
    );
}

function ProjectLink() {
    // Styles
    const dispatch = useDispatch()
    const demoProcessObject = useSelector(state => state.demoProcess)
    const useStyles = makeStyles(() => ({
        root: {
            width: "50%",
        },
    }));
    const classes = useStyles();
    const handleChange = (e) => {
        dispatch(setAsset_link(e.target.value))
    }
    return <Forms>
        <TextField
            placeholder={"Link"}
            value={demoProcessObject.asset_link}
            className={classes.root}
            id="outlined-basic"
            variant="outlined"
            name="fullname"
            onChange={handleChange}
        />
    </Forms>
}

