import React from "react";
import img1 from "../assets/impactImg.svg";
import img2 from "../assets/impactImg2.svg";
import img3 from "../assets/impactImg3.svg";
import IconImage from "../assets/IconImages.svg";
import HeroSection from "../components/Hero/HeroSection";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

const H1 = styled.h1`
    margin-top: 12rem;
    margin-bottom: 6rem;
    text-align: center;
`;

const Div = styled.div`
    padding: 6rem 5rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),
        url(${img1});
    background-size: cover;
    color: #fff;

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 6rem 2.5rem;
    }
`;

const Heading = styled.h2`
    text-align: center;
    margin-bottom: 6rem;
    font-weight: 300;
`;

const SmallDiv = styled.div`
    margin-bottom: 5rem;

    & h1 {
        font-size: 6rem;
        font-weight: 300;
    }

    & p {
        font-size: 2rem;
    }
`;

const Div2 = styled.div`
    padding: 6rem 5rem;
    background-image: url(${img2});
    background-size: cover;
    display: flex;
    justify-content: flex-end;

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 6rem 2.5rem;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        justify-content: center;
    }

    & div {
        background-color: #fff;
        border-radius: 20px;
        padding: 2rem;
        max-width: 40%;

        @media ${(props) => props.theme.breakpoints.sm} {
            max-width: 60%;
        }

        @media ${(props) => props.theme.breakpoints.xs} {
            max-width: fit-content;
        }

        display: flex;
        align-items: center;
        flex-direction: column;

        & h2 {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
        }

        & h1.percentage {
            text-align: center;
            font-size: 6rem;
            color: #0e4da4;
        }

        & img {
            width: 17rem;
            height: auto;
            margin-bottom: 2rem;
        }

        & p {
            font-size: 2rem;
        }
    }
`;

const Div3 = styled.div`
    padding: 6rem 5rem;
    background-image: url(${img3});
    background-size: cover;

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 6rem 2.5rem;
    }

    & h1 {
        font-size: 3.8rem;
        font-weight: 300;
        margin-bottom: 2rem;
    }

    & p {
        font-size: 2rem;
    }

    & div {
        background-color: #1d1d1d;
        padding: 2rem;
        margin-top: 25rem;
        color: #fff;
        background: rgba(0, 0, 0, 0.6);
        font-size: 2rem;
        font-weight: 300;
        text-align: center;
    }
`;

const Impact = () => {
    return (
        <>
            <Helmet>
                <title>Impact | DeepGears</title>
                <meta
                    name="description"
                    content="DeepGears, a tech-enabled catalyst of sustainability"
                />
                <link rel="canonical" href="/Impact" />
            </Helmet>

            <H1>DeepGears, a tech-enabled catalyst of sustainability</H1>
            <Div>
                <Heading>There's no such thing as a "free" return</Heading>

                <SmallDiv>
                    <h1>30%</h1>
                    <p>
                        The average cost of returns as share of the list price
                    </p>
                </SmallDiv>

                <SmallDiv>
                    <h1>15M</h1>
                    <p>Tons of CO2 emitted due to returns, in the US alone</p>
                </SmallDiv>
            </Div>

            <Div2>
                <div>
                    <h2>People buy what they don't need</h2>
                    <h1 className="percentage">+70%</h1>
                    <img src={IconImage} alt="icons" />
                    <p>
                        Average share of clothes unworn over 1 year in the UK
                        (FashionUnited)
                    </p>
                </div>
            </Div2>

            <Div3>
                <h1>
                    10,000 items of clothing are sent to landfill every 5
                    minutes
                </h1>
                <p>Equivalent to £140 million in value every year</p>

                <div>
                    Only &lt;50% of returns are successively re-sold at full
                    price. The majority of unsold returned items are typically
                    incinerated or sent to landfill
                </div>
            </Div3>

            <HeroSection />
        </>
    );
};

export default Impact;
