import React from 'react'
import AppTest from './components/AppTest'
import Header from './components/Header'
import OurImpact from './components/OurImpact'
import Universities from './components/Universities'
import "./components/styles/main.scss"
import Cta from './components/Cta'
import News from './components/News'
import InPress from "./components/InPress"

export default function HomePageV2() {
    return (
        <div style={{
            display: "grid",
            placeItems: "center"
        }} id="home-page">
            <Header />
            <div className="header-container news-section">
                <div className="text-size-4">Latest News</div>
            </div>
            <InPress />
            <News />
            <AppTest />
            <OurImpact />
            <Universities />
            <Cta />
        </div>
    )
}
