import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { setGarmentTemplate, setVideo, setHasvideo, setResultPaneOpen } from "../../../model/DemoProcess"
import "../styles/Result.scss"

import { allGarmentTemplates } from "./GarmentTemplatesService"

import { imagesLinks, endpoints } from '../config'

import classnames from "classnames"
import Lottie from 'react-lottie';

import { useWindowSize } from 'react-window-size-hooks';
import { mobileWidth, smallHeight } from '../../breakpoints/breakpoint'

export function Template({ data, url, name, handleClick, hasVideo }) {
    const [listOfItems, setlistOfItems] = useState([])
    const [selected, setselected] = useState(-1)
    const [loading, setloading] = useState(true)
    const dispatch = useDispatch()
    const garmentTemplate = useSelector(state => state.demoProcess.garmentTemplate)
    useEffect(() => {
        dispatch(allGarmentTemplates({
            data: data,
            url: url,
        })).then((result) => {
            let listOfItems = result.map((item, idx) => {
                return {
                    image: imagesLinks.imageSource + item.id,
                    text: item[name],
                    price: item.price
                }
            })
            setloading(false)
            setlistOfItems(listOfItems)
        })
    }, [garmentTemplate])

    return (
        <div id="demo-process-result">
            <div className="d-flex justidy-content-between header">
                <h4>All Results</h4>
            </div>
            <div className="d-flex all-images">
                {listOfItems.map((item, idx) => {
                    return <SingleImage image={item.image} text={item.text} handleClick={handleClick} hasVideo={hasVideo} price={item.price} id={idx} select={{ selected, setselected }} key={idx} data={data} />
                })}
            </div>
            <div className="empty">
                {(!listOfItems.length && !loading) && <EmptyList link={require("../../../assets/emptyAnimation.json")} text="Please select one garment template" widthStyle="300px" />}
                {(loading) && <EmptyList link={require("../../../assets/loaderAnimation.json")} widthStyle="200px" />}
            </div>
        </div>
    )
}
export default function GarmentTemplate() {
    const animation = useSelector(state => state.demoProcess.animation)
    const data = {
        animation: "default",
        typeFile: "pic"
    }
    const dispatch = useDispatch()
    const handleClick = (text) => {
        dispatch(setGarmentTemplate(text))
    }
    return <><Template data={data} url={endpoints.allGarmentTemplates} name="garment_type" handleClick={handleClick} hasVideo={false} /></>
}
function SingleImage({ image, text, handleClick, hasVideo, price, id, select, data }) {
    const dispatch = useDispatch()
    const selectCard = () => { return select.selected === id }
    const { height,width } = useWindowSize();
    const video = () => {
        if (!(data?.typeFile)) {
            return true
        }
    }
    return <div className={classnames("singleImage", { "singleImageSelected": selectCard(), "video": video() })} onClick={() => {
        let information = {
            title: text,
            price: price?.price || 0
        }
        handleClick(information)
        dispatch(setHasvideo(hasVideo))
        dispatch(setVideo(image))
        select.setselected(id)
        if((height<=smallHeight) && (width<=mobileWidth)){
            dispatch(setResultPaneOpen(false))
        }
    }} key={id}>
        {!hasVideo && <img src={image} alt="" />}
        {hasVideo && <video src={image} ></video>}
        <div className="text">{text}</div>
    </div>
}


export function EmptyList(props) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: props.link,
        renderSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    return <>
        <div>
            <Lottie
                options={defaultOptions}
                width={props.widthStyle}
            />
            {props.text && <div className="empty-text">{props.text}</div>}
        </div>
    </>
}

