import React from 'react'
import "./index.scss"
import { Link } from 'react-router-dom'

export default function Sizing() {
    return (
        <div className="faq-main">
            <div id="faq" className="intergration" >
                <div className="d-flex justify-content-center ">
                    <div className="heading">
                        <h3>Sizing & Fitting</h3>
                        <h6>Our sizing & fitting solution explained in less than 30 seconds</h6>
                    </div>
                </div>
                <div className="d-flex justify-content-center "><video src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/sizing_and_fitting.mp4" autoPlay muted loop></video></div>
                <div className="d-flex justify-content-center "><h3 className="faq-header">Frequently Asked Questions</h3></div>
                <div className="all-faq">
                    <SingleFaq header="How do I integrate your solution with my website?" desc="Our app runs on a separate package that requires only 2 lines of code to be placed anywhere on your website window" />
                    <SingleFaq header="What information do you need from me?" desc="We would just need basic information from your size guide and garment fabric and our AI-driven software will do the rest " />
                    <SingleFaq header="How much does it cost?" desc="We charge a monthly recurring fee depending on how many garments we provide our solution for.
We do not have a fixed pricing scheme as we adapt our rates based on our clients’ needs" />
                    <SingleFaq header="What’s the impact on my bottom line?" desc="We have observed a 10-20% reduction in returns" />
                </div>
                <div className="find-out-more d-flex justify-content-center">
                    <Link to="/sales"><div className="btn btn-primary">Find out more</div></Link>
                </div>
            </div>
        </div>
    )
}
function SingleFaq({ header, desc }) {
    return <div className="single-faq">
        <h4>{header}</h4>
        <h6>{desc}</h6>
    </div>
}

