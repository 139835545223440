import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 6rem 5rem 6rem 5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 6rem 2.5rem 6rem 2.5rem;
    }
`;

export const H2 = styled.h2`
    text-align: center;
    margin-bottom: 6rem;

    @media ${(props) => props.theme.breakpoints.sm} {
        text-align: left;
    }
`;

export const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media ${(props) => props.theme.breakpoints.sm} {
        flex-direction: column;
    }
`;

export const DivText = styled.div`
    @media ${(props) => props.theme.breakpoints.sm} {
        margin-bottom: 4rem;
    }

    & li {
        list-style: none;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #ababab;
        cursor: pointer;
    }

    & ul li:last-child {
        border-bottom: none;
    }

    @media ${(props) => props.theme.breakpoints.xs} {
        width: 100%;
    }
`;

export const Img = styled.img`
    width: 40rem;
    height: 27rem;

    @media ${(props) => props.theme.breakpoints.sm} {
        align-self: center;
    }

    @media screen and (max-width: 350px) {
        width: 31.5rem;
        height: 20.5rem;
    }
`;

export const Li = styled.div`
    display: flex;
    justify-content: space-between;
    @media ${(props) => props.theme.breakpoints.sm} {
        flex-direction: row !important;
    }
`;

export const Icon = styled.div`
    color: #1d499a;
    margin-left: 20rem;
    font-size: 2rem;

    @media ${(props) => props.theme.breakpoints.xs} {
        margin-left: 6.5rem;
    }
`;

export const Text = styled.p`
    margin-top: 2.5rem;
    font-size: 1.6rem;
    max-width: 45rem;

    @media ${(props) => props.theme.breakpoints.md} {
        max-width: fit-content;
    }
`;
