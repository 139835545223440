import styled from "styled-components";

export const Div = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media ${(props) => props.theme.breakpoints.sm} {
        flex-direction: column;
        align-items: center;
    }
`;

export const Card = styled.div`
    padding: 2rem;
    width: 50rem;
    height: auto;

    @media ${(props) => props.theme.breakpoints.xxs} {
        width: 38.5rem;
    }

    @media screen and (max-width: 330px) {
        width: 35rem;
    }

    &:hover {
        cursor: pointer;
    }
`;

export const H1 = styled.h1`
    margin: 2rem 0;
    font-size: 3rem;

    @media ${(props) => props.theme.breakpoints.xxs} {
        font-size: 2.5rem;
    }

    &:hover {
        color: #0e4da4;
    }
`;

export const Text = styled.p`
    font-size: 2rem;
    color: #9f9f9f;

    /* display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5; */
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-height: 1.5;

    @media ${(props) => props.theme.breakpoints.xxs} {
        font-size: 1.7rem;
    }
`;

export const Img = styled.img`
    width: 100%;
    height: auto;
    border-radius: 10px;

    @media ${(props) => props.theme.breakpoints.xxs} {
        width: 90%;
    }
`;

export const DivText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    & h1 {
        text-align: center;
    }

    & img {
        width: 100%;
        margin-top: 6rem;

        @media ${(props) => props.theme.breakpoints.xs} {
            margin-top: 3rem;
        }
    }

    & p {
        font-size: 2rem;
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;
    }
`;

export const TextWrapper = styled.div`
    margin: 2rem 0;

    & p {
        font-size: 2rem;
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;
    }
`;
