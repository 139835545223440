import React, { useState, useRef } from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./styles/news.scss"

export default function News() {
    let section1 = [
        {
            header: "DeepGears wins European Metaverse Awards - Top Fashion, or Consumer, Brand Initiative",
            content: `DeepGears was selected as Top Fashion, or Consumer, Brand Initiative by a jury of VIP judges including Qualcomm Ventures, HSBC and more `,
            image: "./metaverse_award.webp",
            fitImage: true,
            color: "#CCCBEA"
        },
        {
            header: "DeepGears partners with Vesta E-Fashion",
            content: `DeepGears is partnering with Vesta E-Fashion, experts in 3D digital technology and committed to help companies become more sustainable. DeepGears, will license its modular technology to better cater for fashion retailers needs, across online and phygital applications`,
            image: "./partnership.webp"
        },
        {
            header: "DeepGears x London Fashion Week",
            content: `DeepGears sponsored Jaded Life Collective Fashion Show, by Sibu Dladla, Alicia Vaseghia & co. an evening attended by more than 200 prominent industry figures and mentioned by British Vogue as “Ones to watch 22” `,
            video: "https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/website/LFW_vFF_HDR_filters+(1).mp4"
        },
        {
            header: "Linda Pimmeshofer joins DeepGears as an advisor",
            content: `Linda, Industry adviser - Retail at Microsoft Western Europe has joined DeepGears as an Advisor, providing her great expertise and support to the team taking DeepGears to the next level of growth `,
            image: "https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/website/image+169.png"
        },
        {
            header: "DeepGears sponsored by Mayor of London to attend Slush 2022",
            content: `DeepGears has been selected by the Mayor of London as one of the top 10 startups in the city to participate in a fully sponsored trade mission to Slush 2022`,
            image: "./slush.png"
        },
    ]
    return (
        <div id="news-home-page">
            <div className="carousel-container">
                <Carousel showStatus={false}>
                    {section1.map((item, idx) => {
                        return <SingleNewsElement {...item} key={idx} />
                    })}
                </Carousel>
            </div>
            <div className="desktop-container"><SingleNewsColumn data={section1} /></div>
        </div>
    )
}

const SingleNewsElement = (props) => {
    const [showMore, setshowMore] = useState(false)
    const {
        header,
        content,
    } = props
    const textToShow = useRef()
    const handleReadMore = () => {
        setshowMore(!showMore)
    }
    return <div className={`single-news-element single-news-${props.video && "video"}`}>
        <div>
            <div className="media">
                {props.image && <div className="image-back" style={{ backgroundImage: `url("${props.image}")`, backgroundSize: `${props.fitImage ? "contain" : "cover"}`, backgroundColor: `${props.color ? props.color : "white"}` }}></div>}
                {props.video && <video src={props.video} autoPlay muted controls ></video>}
            </div>
            <div className="header">{header}</div>
        </div>
        <p className={`no-content ${showMore && "artcile-content"}`} style={{ height: `${showMore ? `${textToShow.current.clientHeight}px` : "0px"}` }}>
            <div ref={textToShow}>
                {content}
            </div>
        </p>
        <div className="expand-text" onClick={handleReadMore}>
            {showMore ? <p>Close</p> : <p>Read more</p>}
            <img src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/website/Vector.svg" alt="" className={`expand-icon ${showMore && 'expand-icon-rotate'}`} />
        </div>
    </div>
}
const SingleNewsColumn = (props) => {
    return <>
        <div className="single-news-column">
            {props.data && (props.data.map((item, idx) => {
                return <SingleNewsElement header={item.header} content={item.content} video={item.video} image={item.image} fitImage={item.fitImage} color={item.color} />
            }))}
        </div>
    </>
}
