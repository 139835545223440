import React, { useRef, useEffect } from 'react'
import { animateGsap } from '../gsap/animation'
import cube from "../../../assets/about/Depth Effect.png"
import videoImage from "../../../assets/about/4k.png"
import '../styles/solution.scss'
import { Link } from 'react-router-dom'
import { useWindowSize } from 'react-window-size-hooks';

export default function Solutions() {
    const animateRef = useRef(null)
    const { width, height } = useWindowSize();
    const firstVideo = useRef(null)
    const firstFrame = useRef(null)
    const secondVideo = useRef(null)
    const secondFrame = useRef(null)
    useEffect(() => {
        function videoFrame(video, frame) {
            const videoWidth = video.current.getBoundingClientRect().width
            const videoHeight = video.current.getBoundingClientRect().height
            let padding=10
            frame.current.style.minHeight = `${videoHeight + padding}px`
            frame.current.style.minWidth = `${videoWidth}px`
        }
        videoFrame(firstVideo, firstFrame)
        videoFrame(secondVideo, secondFrame)
        const interval=setInterval(() => {
            videoFrame(firstVideo, firstFrame)
            videoFrame(secondVideo, secondFrame)
        }, 100);
        return ()=>{
            clearInterval(interval)
        }
    }, [width, height])

    useEffect(() => {
        animateGsap(animateRef)
    }, [])
    return (
        <div id="solutions" className="solution" ref={animateRef}>
            <h1 className="d-flex justify-content-center">Our fitting room solutions</h1>
            <div className="d-md-flex all-solutions">
                <div className="solution-item">
                    <div className="d-flex top-text">
                        <img src={cube} alt="" />
                        <h3>Immersive 3D experience</h3>
                    </div>
                    <div className="h6">3D objects that can freely rotated by users or thrown onto any flat surface in AR mode</div>
                    <div className="solution-video-wrapper">
                        <div className="iphone-frame" ref={firstFrame}></div>
                        <video src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/attempt1.mp4" autoPlay muted loop ref={firstVideo}></video>
                    </div>
                </div>
                <div className="solution-item second-item">
                    <div className="d-flex top-text">
                        <img src={videoImage} alt="" />
                        <h3>Hyper-quality 2D renders</h3>
                    </div>
                    <div className="h6">Set of hyper-quality frames from various camera angles, suitable for any texture and type of material (including fur)</div>
                    <div className="solution-video-wrapper">
                        <div className="iphone-frame" ref={secondFrame}></div>
                        <video src="https://deep-gears-storage.s3.eu-west-2.amazonaws.com/resources/video+5+attempt2.mp4" autoPlay muted loop ref={secondVideo}></video>
                    </div>
                </div>
            </div>
            <div className="find-out-more d-flex justify-content-center">
                <Link to="/sales"><div className="btn btn-primary">Find out more</div></Link>
            </div>
        </div>
    )
}
