import React, { useContext } from "react";
import styled from "styled-components";
import HeroSection from "../components/Hero/HeroSection";
import { AppContext } from "../context/AppContext";

import { Wrapper } from "../components/Reusable/styles";
import { Card, H1, Text, Img, Div } from "../components/BlogCard/styles";
import RichTextResolver from "storyblok-js-client/dist/rich-text-resolver.cjs.js";
import { Link } from "react-router-dom";

const Heading = styled.h1`
    margin-bottom: 4rem;
    font-size: 4rem;
    text-align: center;
`;

const LoadingH1 = styled.h1`
    text-align: center;
`;

const Blog = () => {
    const { isLoading, content } = useContext(AppContext);
    const [data] = content;
    const [loading] = isLoading;
    const resolver = new RichTextResolver();

    return (
        <>
            <Wrapper>
                <Heading>Blog Posts</Heading>
                {loading ? (
                    <LoadingH1>Is Loading...</LoadingH1>
                ) : (
                    <Div>
                        {data.map((item) => (
                            <Card key={item.content._uid}>
                                <Img src={item.content.image} />
                                <Link to={`/blog/${item.content._uid}`}>
                                    <H1>{item.content.title}</H1>
                                </Link>
                                <Text
                                // dangerouslySetInnerHTML={{
                                //     __html: resolver.render(
                                //         item.content.long_text
                                //     ),
                                // }}
                                >
                                    {
                                        item.content.long_text.content[0]
                                            .content[0].text
                                    }
                                </Text>
                            </Card>
                        ))}
                    </Div>
                )}
            </Wrapper>
            <HeroSection />
        </>
    );
};

export default Blog;
