import React from 'react'
import HomePageV2 from '../components/homePagev.2/HomePageV2'

export default function HomeV2() {
  return (
    <div>
        <HomePageV2 />
    </div>
  )
}
