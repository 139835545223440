import React from 'react'

export default function Lines() {
  return (
      <div className="line-container">
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
        <div className="line4"></div>
      </div>
  )
}
