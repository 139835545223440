import React from 'react'
import "./footerv2.scss"
import logo from "../../logoWhite.svg";
import Lines from './Lines'
import linkedin from "../../assets/linkedin.svg"
import instagram from "../../assets/instagram.svg"
import twitter from "../../assets/twitter.svg"
import { Link } from "react-router-dom";

export default function Footerv2() {
    const latestDate=()=>{
        const d = new Date();
        let year = d.getFullYear();
        return year
    }
    return (
        <div id="footerv2">
            <div className="line">
                <Lines />
            </div>
            <div className="footer-content">
                <div className="footer-logo">
                    <img src={logo} alt="" />
                </div>
                <div className="d-flex sections-container">
                    <div className="footer-section">
                        <div className="footer-title">Data Privacy
                            <div className="line" style={{ backgroundColor: "#F2C94B" }}></div>
                        </div>
                        <div className="footer-text-size"><Link to="/Privacy">Privacy Policy</Link></div>
                    </div>
                    <div className="footer-section">
                        <div className="footer-title">Contacts
                            <div className="line" style={{ backgroundColor: "#EB5757" }}></div>
                        </div>
                        <div className="footer-text-size">Send us an email at:<br />info@deepgears.com</div>
                    </div>
                </div>
                <div className="copyright">
                    <hr />
                    <div className="d-flex justify-content-between">
                        <div className="copyright-text footer-text-size">© {latestDate()} by DeepGears Ltd.</div>
                        <div className="social-media">
                            <a href="https://www.linkedin.com/company/deepgears/mycompany/" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="" /></a>
                            <a href="https://www.instagram.com/deepgears_ltd/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
