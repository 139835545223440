import React, { useEffect } from "react";
import { Layout } from "./styles/Globals";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    useRouteMatch,
} from "react-router-dom";

import Navbar from "./components/Nav/Navbar";
import HomePage from "./pages/HomePage";
import Sales from "./pages/Sales";
import Careers from "./pages/Careers";
import OurCareers from "./pages/OurCareers";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Privacy from "./pages/Privacy";
import Team from "./pages/Team";
import Impact from "./pages/Impact";
import Product from "./pages/Product";
import Blog from "./pages/Blog";
import Referral from "./pages/Referral/Referral";
import { AppProvider } from "./context/AppContext";

import ReactGA from "react-ga";
import { HelmetProvider } from "react-helmet-async";
import BlogPost from "./pages/BlogPost";
import Demo from "./pages/Demo";
import DemoProcess from "./pages/DemoProcess";
import HomeV2 from "./pages/HomeV2";
import Footerv2 from "./components/Footer/Footerv2";
import AboutPage from "./pages/AboutPage";
import Sizing from "./components/sizing/Sizing";


function App() {
    const { path } = useRouteMatch();
    const initReactGA = () => {
        ReactGA.initialize("UA-199729560-1");

        ReactGA.pageview(window.location.pathname + window.location.search);
    };

    useEffect(() => {
        initReactGA();
    }, []);

    return (
        <AppProvider>
            <HelmetProvider>
                <Layout>
                    <Router>
                        <Navbar />

                        <ScrollToTop>
                            <Switch>
                                <Route exact path="/">
                                    <HomeV2  />
                                </Route>
                                <Route exact path="/deep-gears-studio">
                                    <Demo />
                                </Route>
                                <Route exact path="/virtual-fitting-room">
                                    <AboutPage />
                                </Route>
                                <Route exact path="/sizing-and-fitting">
                                    <Sizing />
                                </Route>
                                <Route exact path="/demo-process">
                                    <DemoProcess />
                                </Route>
                                <Route exact path="/Sales">
                                    <Sales text={true} />
                                </Route>
                                <Route exact path="/Careers">
                                    <Careers />
                                </Route>
                                <Route exact path="/OurCareers">
                                    <OurCareers />
                                </Route>
                                <Route exact path="/Privacy">
                                    <Privacy />
                                </Route>
                                <Route exact path="/Team">
                                    <Team />
                                </Route>
                                <Route exact path="/Impact">
                                    <Impact />
                                </Route>
                                <Route exact path="/Referral">
                                    <Referral />
                                </Route>
                                <Route exact path="/Product">
                                    <Product />
                                </Route>
                                <Route exact path="/blog">
                                    <Blog />
                                </Route>

                                <Route
                                    path={`/blog/:id`}
                                    component={BlogPost}
                                />
                            </Switch>
                        </ScrollToTop>
                        <Footerv2 />
                    </Router>
                </Layout>
            </HelmetProvider>
        </AppProvider>
    );
}

export default App;
