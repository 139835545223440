import styled from "styled-components";
import background from "./../../../assets/background2.svg";

export const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
`;

export const Div = styled.div`
    background-color: #0e4da4;
    color: #fff;
    padding: 12rem 6rem 12rem 6rem;

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 12rem 2.5rem 12rem 2.5rem;
    }

    & h1 {
        margin-bottom: 2rem;
    }

    & h2 {
        font-weight: 300;
    }
`;

export const Div2 = styled.div`
    background-color: #f6efe8;
    padding: 5rem 6rem 5rem 6rem;

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 8rem 2.5rem 5rem 2.5rem;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 47% 48% 10% 10% / 30% 30% 0% 0%;

    margin: -7.8rem 0 0 0;

    & h2 {
        margin-bottom: 2rem;
        align-self: center;
    }
`;

export const List = styled.ol`
    font-size: 2rem;
    margin-left: 2rem;

    & li {
        margin-bottom: 1rem;
    }

    & a {
        color: #0e4da4;
    }

    & span {
        color: #0e4da4;
        cursor: pointer;
    }
`;

export const Wrapper = styled.div`
    background-image: url(${background});
    height: 100%;
    padding: 5rem;

    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;

    display: flex;
    align-items: center;

    @media ${(props) => props.theme.breakpoints.xs} {
        flex-direction: column-reverse;
        padding: 2.5rem;
    }

    & img {
        height: 45rem;
        margin-right: 5rem;

        @media ${(props) => props.theme.breakpoints.sm} {
            margin-right: 2.5rem;
        }

        @media ${(props) => props.theme.breakpoints.xs} {
            margin-right: 0;
            margin-top: 2rem;
        }
    }

    & h1 {
        font-weight: 300;
        margin-bottom: 3rem;
    }
`;

export const Shape2 = styled.div`
    border-radius: 50% 50% 30% 30% / 0% 0% 40% 40%;
    background-color: #0e4da4;
    color: #fff;

    height: 20rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    padding: 4rem;

    @media ${(props) => props.theme.breakpoints.xs} {
        padding: 2.5rem;
    }

    & h1 {
        font-weight: 300;
        text-align: center;
        font-size: 3.8rem;

        @media ${(props) => props.theme.breakpoints.xs} {
            font-size: 3rem;
            margin-top: 2rem;
        }
    }
`;

export const SlideIn = styled.div`
    background-color: #fff;
    width: 90rem;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    padding: 4rem 2rem;
    border-radius: 1rem;

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    & h1 {
        text-align: center;
        margin-bottom: 4rem;
        font-size: 30px;
    }

    & form {
        & button {
            margin: 2rem 0 0 10rem;
        }
    }

    & div {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        padding: 0 10rem;

        & label {
            margin-bottom: 1rem;
            font-size: 1.6rem;
            font-weight: 300;
        }

        & input {
            height: 4rem;
            padding: 0 1rem;
            font-size: 1.6rem;
        }
    }
`;
