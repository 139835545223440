const validate = (values) => {
    let errors = {};
    const pattern = new RegExp(/^[0-9\b]+$/);

    // Name
    if (!values.firstName) {
        errors.firstName = "Name is required";
    }

    if (!values.lastName) {
        errors.lastName = "Name is required";
    }

    // Email
    if (!values.email) {
        errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = "Email address is invalid";
    }

    // Number
    if (!values.number) {
        errors.number = "Phone number is required";
    } else if (pattern.test(values.number)) {
        errors.number = "Phone number is invalid";
    }

    // Role
    if (!values.role) {
        errors.role = "Role is required";
    }

    return errors;
};

export default validate;
